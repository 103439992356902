import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  FetchAnalysisSourceBreakdown_Trend,
  FetchAnalysisSourceBreakdown_Type,
  FetchAnalysisSourceBreakdown_Territory,
  downloadSourcesBreakdownData,
} from "./pubAnalysisSourceBreakdownAPI";
import { PubAnalysisSourceData } from "./pubAnalysisSourceBreakdownAPI";
import { getPubIncomeTypeDetails } from "../../../../app/utils/incomeType";

export declare type PubAnalysisSourceBreakdownProps =
  | PubAnalysisSourceData
  | null
  | undefined;

export interface pubAnalysisSourceBreakdownState {
  sourceBreakdownType: {
    dataArray: Array<PubAnalysisSourceBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  sourceBreakdownTrend: {
    dataArray: Array<PubAnalysisSourceBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  sourceBreakdownTerritory: {
    dataArray: Array<PubAnalysisSourceBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  sourceBreakdownDownloadStatus: "idle" | "loading" | "failed";
}

const initialState: pubAnalysisSourceBreakdownState = {
  sourceBreakdownType: { dataArray: [], status: "noData" },
  sourceBreakdownTrend: { dataArray: [], status: "noData" },
  sourceBreakdownTerritory: { dataArray: [], status: "noData" },
  sourceBreakdownDownloadStatus: "idle",
};

export const FetchPubAnalysisSourceBreakdown_Type_Thunk = createAsyncThunk(
  "publishing/FetchPubAnalysisSourceBreakdown_Type",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSourceBreakdown_Type(params, thunkAPI);
  }
);

export const FetchPubAnalysisSourceBreakdown_Trend_Thunk = createAsyncThunk(
  "publishing/FetchPubAnalysisSourceBreakdown_Trend",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSourceBreakdown_Trend(params, thunkAPI);
  }
);

export const FetchPubAnalysisSourceBreakdown_Territory_Thunk = createAsyncThunk(
  "publishing/FetchPubAnalysisSourceBreakdown_Territory",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSourceBreakdown_Territory(params, thunkAPI);
  }
);

export const pubAnalysisSourcesBreakdown_Download_Thunk = createAsyncThunk(
  "pubAnalysis/breakdown/downloadSources",
  async (payload: any, thunkAPI) => {
    const response = await downloadSourcesBreakdownData(payload, thunkAPI);
    return response;
  }
);

export const FindPubAnalysisSourceBreakdown = (
  sources: Array<PubAnalysisSourceBreakdownProps>,
  sourceCode: string
): PubAnalysisSourceData | null => {
  const breakdown = sources.find((source) => {
    return source?.sourceCode === sourceCode;
  });
  return breakdown ? breakdown : null;
};

export const pubAnalysisSourceBreakdownSlice = createSlice({
  name: "pubAnalysisSourceBreakdown",
  initialState,
  reducers: {
    clearTypeBreakdownData: (state, action: any) => {
      state.sourceBreakdownType.dataArray = [];
      state.sourceBreakdownType.status = "noData";
    },
    clearTrendBreakdownData: (state, action: any) => {
      state.sourceBreakdownTrend.dataArray = [];
      state.sourceBreakdownTrend.status = "noData";
    },
    clearTerritoryBreakdownData: (state, action: any) => {
      state.sourceBreakdownTerritory.dataArray = [];
      state.sourceBreakdownTerritory.status = "noData";
    },
  },
  extraReducers: (builder) => {
    builder
      // type
      .addCase(
        FetchPubAnalysisSourceBreakdown_Type_Thunk.fulfilled,
        (state, action) => {
          const typeData = action.payload;
          typeData.sourceCode = action.meta.arg.sourceCode;
          typeData?.incomeTypes?.forEach((incomeType: any) => {
            const color = getPubIncomeTypeDetails(
              incomeType?.mainIncType
            ).color;
            incomeType.color = color;
          });
          state.sourceBreakdownType.dataArray.push(typeData);
          state.sourceBreakdownType.status = "idle";
        }
      )
      .addCase(FetchPubAnalysisSourceBreakdown_Type_Thunk.pending, (state) => {
        state.sourceBreakdownType.status = "loading";
      })
      .addCase(FetchPubAnalysisSourceBreakdown_Type_Thunk.rejected, (state) => {
        state.sourceBreakdownType.status = "failed";
      })
      // trend
      .addCase(
        FetchPubAnalysisSourceBreakdown_Trend_Thunk.fulfilled,
        (state, action) => {
          const typeData = action.payload;
          typeData.sourceCode = action.meta.arg.sourceCode;
          state.sourceBreakdownTrend.dataArray.push(typeData);
          state.sourceBreakdownTrend.status = "idle";
        }
      )
      .addCase(FetchPubAnalysisSourceBreakdown_Trend_Thunk.pending, (state) => {
        state.sourceBreakdownTrend.status = "loading";
      })
      .addCase(
        FetchPubAnalysisSourceBreakdown_Trend_Thunk.rejected,
        (state) => {
          state.sourceBreakdownTrend.status = "failed";
        }
      )
      // territory
      .addCase(
        FetchPubAnalysisSourceBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          const territoryData = action.payload;
          territoryData.sourceCode = action.meta.arg.sourceCode;
          state.sourceBreakdownTerritory.dataArray.push(territoryData);
          state.sourceBreakdownTerritory.status = "idle";
        }
      )
      .addCase(
        FetchPubAnalysisSourceBreakdown_Territory_Thunk.pending,
        (state) => {
          state.sourceBreakdownTerritory.status = "loading";
        }
      )
      .addCase(
        FetchPubAnalysisSourceBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.sourceBreakdownTerritory.status = "failed";
        }
      )
      // download
      .addCase(
        pubAnalysisSourcesBreakdown_Download_Thunk.fulfilled,
        (state) => {
          state.sourceBreakdownDownloadStatus = "idle";
        }
      )
      .addCase(pubAnalysisSourcesBreakdown_Download_Thunk.pending, (state) => {
        state.sourceBreakdownDownloadStatus = "loading";
      })
      .addCase(pubAnalysisSourcesBreakdown_Download_Thunk.rejected, (state) => {
        state.sourceBreakdownDownloadStatus = "failed";
      });
  },
});

export const pubAnalysisSourceBreakdownTypeSelector = (state: RootState) =>
  state.pubAnalysisSourceBreakdown.sourceBreakdownType;
export const pubAnalysisSourceBreakdownTrendSelector = (state: RootState) =>
  state.pubAnalysisSourceBreakdown.sourceBreakdownTrend;
export const pubAnalysisSourceBreakdownTerritorySelector = (state: RootState) =>
  state.pubAnalysisSourceBreakdown.sourceBreakdownTerritory;
export const pubAnalysisSourceBreakdownDownloadStatusSelector = (
  state: RootState
) => state.pubAnalysisSourceBreakdown.sourceBreakdownDownloadStatus;

export const {
  clearTypeBreakdownData: clearTypeBreakdownDataAction,
  clearTrendBreakdownData: clearTrendBreakdownDataAction,
  clearTerritoryBreakdownData: clearTerritoryBreakdownDataAction,
} = pubAnalysisSourceBreakdownSlice.actions;

export default pubAnalysisSourceBreakdownSlice.reducer;
