import React from "react";

function ArrowsCircleIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      name="ArrowsCircleIcon"
      {...props}
    >
      <path
        fill="#F7B06E"
        stroke="#F7B06E"
        d="m12.224 3.018.847.82V.572a.071.071 0 0 1 .143 0V4.002c0 .03-.02.058-.049.068L9.737 5.212a.072.072 0 0 1-.045-.136l2.531-.845.731-.243-.52-.568A6.786 6.786 0 0 0 .643 8 .071.071 0 1 1 .5 8v-.001a6.914 6.914 0 0 1 11.724-4.98ZM1.143 12c-.001-.246.157-.466.39-.544l3.43-1.143a.572.572 0 0 1 .36 1.086L1.143 12Zm0 0v3.429a.571.571 0 0 0 1.143 0v-1.411L1.143 12Zm13.071-4a.071.071 0 0 1 .143 0v.001a6.914 6.914 0 0 1-1.947 4.823l.36.348-.36-.348a6.914 6.914 0 0 1-9.777.158l-.847-.82V15.428a.071.071 0 1 1-.143 0V11.999c0-.031.02-.059.049-.069l3.428-1.142a.072.072 0 0 1 .045.137l-2.531.844-.731.243.52.569A6.786 6.786 0 0 0 14.214 8Z"
      />
    </svg>
  );
}

export default ArrowsCircleIcon;
