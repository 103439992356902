export const USER_API_RESOURCE_BASE_URLS = {
  me: "/me",
  users: "/users",
  clients: "/clients",
  activityLog: "/activity-log",
  recaptcha: "/recaptcha",
  contactUs: "/contactUs",
  news: "/news",
  languages: "/languages",
  songs: "/songs",
  territories: "/territories",
  digital: "/digital",
  types: "/types",
  contacts: "/contacts",
  forms: "/forms",
  formsDownload: "/forms/download",
  stats: "/stats",
  periods: "/periods",
  parents: "/parents",
  advancerequestSend: "/advancerequest/send",
  permissionsSidebar: "/permissions/sidebar",
  clientLists: "/clientLists",
  clientsSelected: "/clients/selected",
  clientListsSelected: "/clientLists/selected",
  pipelineTerritories: "/pipeline/territories",
  advancerequestLimit: "/advancerequest/limit",
  combinedDashboard: "/combinedDashboard",
  mechanicalOverview: "/mechanical/overview",
  mechanicalOverviewDownload: "/mechanical/overview/download",
  digitalTrends: "/digital/trends",
  digitalTerritories: "/digital/territories",
  songsType: "/songs/type",
  songsTerritory: "/songs/territory",
  songsSource: "/songs/source",
  songsDetails: "/songs/details",
  sourcesType: "/sources/type",
  sourcesTerritory: "/sources/territory",
  sourcesTrends: "/sources/trends",
  sources: "/sources",
  songsTop5: "/songs/top5",
  pipelineTypes: "/pipeline/types",
  pipelineSongs: "/pipeline/songs",
  pipelineSources: "/pipeline/sources",
  overview: "/overview",
  balanceDetail: "/balance/detail",
  overviewDownload: "/overview/download",
  songregistration: "/songregistration",
  songregistrationBreakdown: "/songregistration/breakdown",
  songregistrationSearch: "/songregistration/search",
  songregistrationDownload: "/songregistration/download",
  publishingCreditnotes: "/publishing/creditnotes",
  recordingDigital: "/recording/digital",
  recordingSources: "/recording/sources",
  channels: "/channels",
  tracks: "/tracks",
  recordingTerritories: "/recording/territories",
  albums: "/albums",
  albumsTerritories: "/albums/territories",
  albumsTrends: "/albums/trends",
  albumsTracks: "/albums/tracks",
  channelsTerritories: "/channels/territories",
  channelsTrends: "/channels/trends",
  channelsProducts: "/channels/products",
  recordingDigitalTrends: "/recording/digital/trends",
  recordingDigitalTerritories: "/recording/digital/territories",
  recordingDigitalProducts: "/recording/digital/products",
  recordingSourcesChannels: "/recording/sources/channels",
  recordingSourcesTerritories: "/recording/sources/territories",
  recordingSourcesTrends: "/recording/sources/trends",
  tracksTrends: "/tracks/trends",
  tracksTerritories: "/tracks/territories",
  tracksSources: "/tracks/sources",
  analysisDownload: "/analysis/download",
  statementsPeriod: "/statements-period",
  dashboardTopProducts: "/dashboard/topProducts",
  dashboardTopTerritories: "/dashboard/topTerritories",
  albumsTop5: "/albums/top5",
  dashboardTrends: "/dashboard/trends",
  recordingOverview: "/recording/overview",
  recordingOverviewDetails: "/recording/overview/details",
  recordingOverviewCosts: "/recording/overview/costs",
  recordingOverviewTransactions: "/recording/overview/transactions",
  recordingOverviewCostDownload: "/recording/overview/cost/download",
  recordingOverviewDownload: "/recording/overview/download",
  pipelineChannels: "/pipeline/channels",
  pipelineAlbums: "/pipeline/albums",
  clientPeriodIncome: "/client-period-income",
  statements: "/statements",
  statementsFiles: "/statements/files",
  recordingCreditnotes: "/recording/creditnotes",
  creditnoteFiles: "/creditnote/files",
  sync: "/sync",
  syncDownload: "/sync/download",
  syncOverview: "/sync/overview",
  contactUsGuest: "/contactUs/guest",
  usersForgotPassword: "/users/forgot-password",
  usersForgotUsername: "/users/forgot-username",
  usersOktaGroupAdd: "/users/okta/group/add",
  usersOktaGroupRemove: "/users/okta/group/remove",
  usersGetLogin: "/users/get-login",
  usersOktaUnenroll: "/users/okta/unenroll",
  usersValidateEmail: "/users/validate-email",
  usersAccessRequests: "/users/access-requests",
  accountTypes: "/accountTypes",
  resetPasswordValidate: "/resetPassword/validate",
  digitalFlashPeriods: "/digitalFlash/periods",
  digitalFlashStats: "/digitalFlash/stats",
  digitalFlashGraph: "/digitalFlash/graph",
  digitalFlashTopTracks: "/digitalFlash/topTracks",
  digitalFlashTopAlbums: "/digitalFlash/topAlbums",
  digitalFlashTrack: "/digitalFlash/track",
  digitalFlashAlbum: "/digitalFlash/albums",
  digitalFlashTopRetailers: "/digitalFlash/topRetailers",
  digitalFlashRetailerTracks: "/digitalFlash/retailer/tracks",
  digitalFlashRetailerTerritory: "/digitalFlash/retailer/territory",
  digitalFlashTopTerritories: "/digitalFlash/topTerritories",
  digitalFlashTerritoryRetailers: "/digitalFlash/territory/retailers",
  digitalFlashTerritoryTracks: "/digitalFlash/territory/tracks",
  digitalFlashTerritoryAlbums: "/digitalFlash/territory/albums",
  appBEVersion: "stats/info",
  emailEnroll: "/users/okta/emailEnroll",
};
