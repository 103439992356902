import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonVariantEnum } from "../../../app/types/enums";
import Button from "../../../app/atom/Button";
import Input from "../../../app/atom/Input";
import ErrorMessage from "../../../app/molecules/authErrorMessage";
import styles from "../../../app/molecules/sideBasicWrapper/sideBasic.module.scss";
import SuccessComponent from "./LoginSuccess";
import { IAuthentication } from "../../../app/types/interfaces";
import useLogin from "./useLogin";
import ErrorIcon from "../ErrorIcon";

const LoginForm = ({
  authenticationHooks,
}: {
  authenticationHooks: IAuthentication;
}) => {
  const { t } = useTranslation();
  const loginHooks = useLogin();
  const [errorUsernameMsg, setErrorUsernameMsg] = useState<undefined | string>(
    undefined
  );
  const [errorPasswordMsg, setErrorPasswordMsg] = useState<undefined | string>(
    undefined
  );

  const successMessage =
    (loginHooks.registrationSuccess && t("register.signUpSuccess")) ||
    (loginHooks.forgotUsernameSuccess && t("forgotUsername.retrieveSuccess")) ||
    (loginHooks.forgotPasswordRetrieveSuccess &&
      t("forgotPassword.retrieveSuccess")) ||
    (loginHooks.resetPasswordSuccess && t("resetPassword.resetSuccess"));

  const onUsernameChange = useCallback(
    (value: string) => {
      setErrorUsernameMsg(undefined);
      authenticationHooks.setUsername(value);
    },
    [authenticationHooks]
  );

  const onPasswordChange = useCallback(
    (value: string) => {
      setErrorPasswordMsg(undefined);
      authenticationHooks.setPassword(value);
    },
    [authenticationHooks]
  );

  const onUsernameBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      let error: string | undefined = undefined;
      if (value === "" || value === undefined) {
        error = t("forgotPassword.errorEmptyEMail") as string;
      }
      setErrorUsernameMsg(error);
    },
    [t]
  );

  const onPasswordBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      let error: string | undefined = undefined;
      if (value === "" || value === undefined) {
        error = t("loginMFA.pleaseComplete") as string;
      }
      setErrorPasswordMsg(error);
    },
    [t]
  );

  const validateForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const formElements = e.currentTarget?.elements;
      const usernameField = formElements.namedItem(
        "username"
      ) as HTMLInputElement | null;
      if (usernameField) {
        onUsernameBlur({
          target: usernameField,
        } as React.ChangeEvent<HTMLInputElement>);
      }

      const passwordField = formElements.namedItem(
        "password"
      ) as HTMLInputElement | null;
      if (passwordField) {
        onPasswordBlur({
          target: passwordField,
        } as React.ChangeEvent<HTMLInputElement>);
      }

      return !!usernameField?.value.length && !!passwordField?.value.length;
    },
    [onPasswordBlur, onUsernameBlur]
  );

  const onFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (validateForm(e)) {
        authenticationHooks.idxAuthenticate(e);
      }
    },
    [authenticationHooks, validateForm]
  );

  return (
    <>
      <form onSubmit={onFormSubmit}>
        <h3>{t("login.registeredUserTxt")}</h3>
        {successMessage && successMessage?.length ? (
          <SuccessComponent successMessage={successMessage} />
        ) : null}

        {authenticationHooks.authApiError && (
          <ErrorMessage
            error={authenticationHooks.authApiError}
            id="loginFormFeedback"
          />
        )}
        {loginHooks.logoutMessage.current}

        <div className={styles.inputContainer}>
          <Button
            className={`forgotUsername_GTM ${styles.grayTextBtn}`}
            variant={ButtonVariantEnum.cleanCta}
            onClick={() => {
              loginHooks.resetStatus();
              authenticationHooks.onClickLink("/mybmg/forgot/username");
            }}
          >
            {t("login.forgotUsername")}
          </Button>
          <Input
            id="username"
            placeholder={t("login.usernamePlaceholder") || ""}
            label={t("login.usernameLabel") || ""}
            className="font-light text-gray-200"
            onChange={(e) => {
              onUsernameChange(e.target.value);
            }}
            onBlur={onUsernameBlur}
            isValid={errorUsernameMsg === undefined}
            errorsMsg={errorUsernameMsg}
            leftIcon={<ErrorIcon />}
          ></Input>
        </div>
        <div className={styles.inputContainer}>
          <Button
            className={`forgotPassword_GTM ${styles.grayTextBtn}`}
            variant={ButtonVariantEnum.cleanCta}
            onClick={() => {
              loginHooks.resetStatus();
              authenticationHooks.onClickLink("/mybmg/forgot/password");
            }}
          >
            {t("login.forgotPassword")}
          </Button>
          <Input
            id="password"
            placeholder={t("login.passwordPlaceholder") || ""}
            label={t("login.passwordLabel") || ""}
            className="mb-6 mt-5 block font-light text-gray-200"
            type="password"
            autoComplete="on"
            onChange={(e) => {
              onPasswordChange(e.target.value);
            }}
            onBlur={onPasswordBlur}
            isValid={errorPasswordMsg === undefined}
            errorsMsg={errorPasswordMsg}
            leftIcon={<ErrorIcon />}
          ></Input>
        </div>
        <Button
          type="submit"
          dataTestId="loginButton"
          className={`login_GTM ${styles.loginBtn}`}
          disabled={!!errorUsernameMsg || !!errorPasswordMsg}
        >
          {t("login.loginBtn")}
        </Button>
        <Button
          className={`registerNewAccount_GTM ${styles.registerLoginLink}`}
          onClick={() => {
            loginHooks.resetStatus();
            authenticationHooks.onClickLink("/mybmg/request/account");
          }}
          variant={ButtonVariantEnum.textLink}
        >
          {t("login.registerBtn")}
        </Button>
      </form>
    </>
  );
};

export default LoginForm;
