/* eslint-disable no-template-curly-in-string */
import * as Yup from "yup";
import { TFunction } from "i18next";

export const setYupLocale = (t: TFunction) => {
  Yup.setLocale({
    mixed: {
      required: t("app.required") ?? "",
    },
  });
};
