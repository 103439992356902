import { SortDownBGIcon, SortUpDownBGIcon } from "../Icon";

const TableColumnSortIcon = (props: {
  isSortColumn: boolean;
  isAsc: boolean;
}) => {
  const { isSortColumn, isAsc } = props;
  return (
    <>
      {isSortColumn ? (
        <span className={`inline-block ${isAsc ? "rotate-180 pr-1" : "pl-1"}`}>
          {SortDownBGIcon}
        </span>
      ) : (
        <span className="inline-block pl-1">{SortUpDownBGIcon}</span>
      )}
    </>
  );
};

export default TableColumnSortIcon;
