import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { windowScrollToTop } from "../../../../app/utils/scrolling";
import RecAnalysisHeader from "../RecAnalysisHeader";
import Search from "../../../../app/molecules/search/Search";
import debounce from "../../../../app/utils/debounce";
import {
  recAnalysisSourcePageStateSelector,
  fetchAnalysisSourcesThunk,
  fetchMoreAnalysisSourcesThunk,
  updateAnalysisSourcePageStateAction,
  resetAnalysisSourcesAction,
  recAnalysisSourcesSelector,
  recAnalysisSourcesDownload,
  recAnalysisSourceStatusSelector,
  recAnalysisDownloadStatusSelector,
  recAnalysisNoDataFlagSelector,
  RecAnalysisSourcePageInitialState,
} from "./recAnalysisSourceSlice";
import {
  clearTrendBreakdownDataAction,
  clearTerritoryBreakdownDataAction,
} from "./recAnalysisSourceBreakdownSlice";
import deepCompare from "../../../../app/utils/deepCompare";
import { recClientSelectionSelector } from "../../recroot/recrootSlice";
import useInfiniteScroll from "../../../../app/hooks/useInfiniteScroll";
import Grid from "../../../../app/atom/Grid/Grid";
import Loader from "../../../../app/atom/Loader/Loader";
import { recAnalysisHeaderSelector } from "../recAnalysisHeaderSlice";
import styles from "../../../../app/molecules/analysis/analysis.module.scss";
import { getNow, getToday } from "../../../../app/utils/dateTime";
import throttle from "../../../../app/utils/throttle";
import AnalysisSourceRightSide from "../../../../app/molecules/analysis/analysisSource/AnalysisSourceRightSide";
import AnalysisSourceLeftSide from "../../../../app/molecules/analysis/analysisSource/AnalysisSourceLeftSide";
import { useWindowSize } from "../../../../app/hooks";
import { recAnalysisSourceTableHeaderData } from "../../../../app/molecules/analysis/analysisSource/analysisSourceTableHeader.const";

const RecAnalysisSource = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const { width: windowWidth } = useWindowSize();

  // seletors
  const clientSelection = useAppSelector(recClientSelectionSelector);
  const recAnalysisSourcePageState = useAppSelector(
    recAnalysisSourcePageStateSelector
  );
  const sources = useAppSelector(recAnalysisSourcesSelector);
  const recAnalysisSourceStatus = useAppSelector(
    recAnalysisSourceStatusSelector
  );
  const recAnalysisDownloadStatus = useAppSelector(
    recAnalysisDownloadStatusSelector
  );
  const recAnalysisNoDataFlag = useAppSelector(recAnalysisNoDataFlagSelector);
  const recAnalysisHeaderState = useAppSelector(recAnalysisHeaderSelector);

  // states
  const [selectedSource, setSelectedSource] = useState<any>(undefined);
  const [analysisSourceStart, setAnalysisSourceStart] = useState(
    recAnalysisSourcePageState.start
  );
  const [analysisSourceSortColumn, setAnalysisSourceSortColumn] = useState(
    recAnalysisSourcePageState.sortColumn
  );
  const [analysisSourceAscOrder, setAnalysisSourceAscOrder] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState(
    recAnalysisSourcePageState.filterText
  );
  const [loaderFlag, setLoaderFlag] = useState(true);
  const [totalAmount, setTotalAmount] = useState({
    currency: sources.data?.totalRoyalty?.currency,
    value: sources.data?.totalRoyalty?.formattedLong,
  });
  const [isSortingLoading, setIsSortingLoading] = useState(false);

  // observers
  const observerTopTarget = useRef<HTMLDivElement | null>(null);
  const { isOnScreen: topTargetIsOnScreen } =
    useInfiniteScroll(observerTopTarget);
  const observerBottomTarget = useRef<HTMLDivElement | null>(null);
  const {
    pageCount: bottomTargetPageCount,
    setPageCount: setBottomTargetPageCount,
  } = useInfiniteScroll(observerBottomTarget);

  // dispatch refs
  const dispatchedAnalysisSource = useRef<any>();
  const dispatchedAnalysisMoreSource = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);
  const abortDispatchedAnalysisSource = useCallback(() => {
    if (dispatchedAnalysisSource.current)
      dispatchedAnalysisSource.current.abort();
  }, []);
  const abortDispatchedAnalysisMoreSource = useCallback(() => {
    if (dispatchedAnalysisMoreSource.current)
      dispatchedAnalysisMoreSource.current.abort();
  }, []);

  const handleAnalysisSourcesFetch = useMemo(
    () =>
      debounce((pageState: any) => {
        if (pageState.start === 0) {
          abortDispatchedAnalysisSource();
          dispatchedAnalysisSource.current = dispatch(
            fetchAnalysisSourcesThunk(pageState)
          );
        } else {
          abortDispatchedAnalysisMoreSource();
          dispatchedAnalysisMoreSource.current = dispatch(
            fetchMoreAnalysisSourcesThunk(pageState)
          );
        }
      }, 500),
    [abortDispatchedAnalysisMoreSource, abortDispatchedAnalysisSource, dispatch]
  );

  const resetAnalysisSources = useCallback(() => {
    dispatch(resetAnalysisSourcesAction({}));
  }, [dispatch]);

  const sortAnalysisSourceBy = useCallback(
    (columnName: string) => {
      setIsSortingLoading(true);
      resetAnalysisSources();
      setLoaderFlag(true);
      setAnalysisSourceSortColumn(columnName);
      if (recAnalysisSourcePageState.sortColumn !== columnName) {
        setAnalysisSourceAscOrder(true);
      } else {
        setAnalysisSourceAscOrder(!analysisSourceAscOrder);
      }
    },
    [
      analysisSourceAscOrder,
      recAnalysisSourcePageState.sortColumn,
      resetAnalysisSources,
    ]
  );

  const handleSourceClick = (clickedSource: any) => {
    !selectedSource ||
    (selectedSource && selectedSource.sourceName !== clickedSource.sourceName)
      ? setSelectedSource(clickedSource)
      : setSelectedSource(undefined);
  };

  const fetchMoreData = useCallback(() => {
    setAnalysisSourceStart(
      () => (bottomTargetPageCount - 1) * recAnalysisSourcePageState.count
    );
    setLoaderFlag(false);
  }, [bottomTargetPageCount, recAnalysisSourcePageState.count]);

  const clearAnalysisSourceBreakdown = useMemo(
    () =>
      throttle((state: any) => {
        dispatch(clearTrendBreakdownDataAction(state));
        dispatch(clearTerritoryBreakdownDataAction(state));
      }, 500),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      abortDispatchedAnalysisSource();
      abortDispatchedAnalysisMoreSource();
      abortDispatchedDownload();
      // clear analysis song page state (keep)
      dispatch(
        updateAnalysisSourcePageStateAction(RecAnalysisSourcePageInitialState)
      );
    };
  }, [
    abortDispatchedAnalysisMoreSource,
    abortDispatchedAnalysisSource,
    abortDispatchedDownload,
    dispatch,
  ]);

  useEffect(() => {
    const hasMore = sources?.data?.sources?.length < sources?.total;
    if (hasMore && bottomTargetPageCount > 1) {
      fetchMoreData();
    }
  }, [
    fetchMoreData,
    bottomTargetPageCount,
    sources?.data?.sources?.length,
    sources?.total,
  ]);

  useEffect(() => {
    if (
      !authState?.isAuthenticated ||
      clientSelection.selectedClients?.length === 0 ||
      recAnalysisHeaderState.selectedPeriods?.length === 0
    ) {
      return;
    }
    const recAnalysisSourceState = {
      ...recAnalysisSourcePageState,
      data: {
        periodIds: recAnalysisHeaderState.selectedPeriods,
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        clientLists: [],
      },
      start: analysisSourceStart,
      sortColumn: analysisSourceSortColumn,
      filterText: searchText,
      sortAscending: analysisSourceAscOrder,
    };
    if (!deepCompare(recAnalysisSourceState, recAnalysisSourcePageState)) {
      dispatch(updateAnalysisSourcePageStateAction(recAnalysisSourceState));
      handleAnalysisSourcesFetch(recAnalysisSourceState);
      clearAnalysisSourceBreakdown(recAnalysisSourceState);
      setSelectedSource(undefined);
    }
    if (
      !deepCompare(
        //ignore start value in deep compare
        { ...recAnalysisSourceState, start: null },
        { ...recAnalysisSourcePageState, start: null }
      )
    ) {
      setAnalysisSourceStart(0);
      setBottomTargetPageCount(1);
    }
  }, [
    analysisSourceAscOrder,
    analysisSourceSortColumn,
    analysisSourceStart,
    authState?.isAuthenticated,
    clearAnalysisSourceBreakdown,
    clientSelection.selectedClients,
    dispatch,
    handleAnalysisSourcesFetch,
    recAnalysisHeaderState.selectedPeriods,
    recAnalysisSourcePageState,
    searchText,
    setBottomTargetPageCount,
  ]);

  useEffect(() => {
    if (sources?.data?.sources?.length) {
      setIsSortingLoading(false);
    }
  }, [sources]);

  useEffect(() => {
    if (!isSortingLoading) {
      setTotalAmount({
        currency: sources?.data?.totalRoyalty?.currency,
        value: sources?.data?.totalRoyalty?.formattedLong,
      });
    }
  }, [sources, isSortingLoading]);

  const handleSearch = useCallback(
    (newSearchText: string) => {
      setLoaderFlag(true);
      resetAnalysisSources();
      setSearchText(newSearchText);
      window?.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    [resetAnalysisSources]
  );

  const handlePeriodSelection = () => setSelectedSource(undefined);

  const handleDownloadBtn = useCallback(() => {
    const params = {
      data: {
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        periodIds: recAnalysisHeaderState.selectedPeriods,
        analyzeBy: "BY_REC_SOURCES",
      },
      fileName: `${t("analysis.source.royalties")}_${t(
        "analysis.source.bySource"
      )}_${getToday()}-${getNow()}.xlsx`,
    };
    abortDispatchedDownload();
    dispatchedDownload.current = dispatch(recAnalysisSourcesDownload(params));
  }, [
    abortDispatchedDownload,
    clientSelection.selectedClients,
    dispatch,
    recAnalysisHeaderState.selectedPeriods,
    t,
  ]);

  return (
    <Grid>
      {(recAnalysisSourceStatus === "loading" ||
        recAnalysisDownloadStatus === "loading") &&
        loaderFlag && <Loader />}
      <div className={styles.analysis}>
        <RecAnalysisHeader
          documentType="RECORDING"
          tab="source"
          handlePeriodSelection={handlePeriodSelection}
          onDownloadBtnClick={handleDownloadBtn}
          isDownloadBtnDisabled={sources?.total === 0 ? true : false}
        />
        <div ref={observerTopTarget} id="observerTopTargetId" />
        {recAnalysisNoDataFlag !== undefined && (
          <div
            id="tab-header"
            className={`sticky top-0 z-[5] ${styles.tabHeader} ${
              topTargetIsOnScreen === false && bottomTargetPageCount > 0
                ? "shadow-lg"
                : ""
            }`}
          >
            <div className={styles.title}>
              <p className={styles.period}>
                {
                  recAnalysisHeaderState.tabSelectedPeriodTitle[
                    recAnalysisHeaderState.selectedPeriodText?.titleVariant
                  ]
                }{" "}
                <span>{recAnalysisHeaderState.selectedPeriodText.details}</span>
              </p>
              {(!!sources?.data?.sources?.length || isSortingLoading) && (
                <p className={styles.totalAmount}>
                  {totalAmount.currency}
                  <strong>{` ${totalAmount.value}`}</strong>
                </p>
              )}
            </div>
            <div className={styles.actions}>
              <Search
                placeholderText={t("analysis.source.searchTextPlaceholder")}
                searchText={recAnalysisSourcePageState.filterText}
                resultsCount={sources?.total}
                onSearchChange={handleSearch}
                showBackToTop={!topTargetIsOnScreen}
                onBackToTopClick={windowScrollToTop}
                status={recAnalysisSourceStatus}
                className={styles.search}
              />
            </div>
            {(!!sources?.data?.sources?.length || isSortingLoading) && (
              <p className={styles.info}>{t("analysis.source.disclaimer")}</p>
            )}
          </div>
        )}
        {recAnalysisNoDataFlag && recAnalysisNoDataFlag?.length && (
          <div className="pl-10 text-xs sm:text-sm">
            {t(`analysis.${recAnalysisNoDataFlag}`)}
          </div>
        )}
        {recAnalysisNoDataFlag === "" && (
          <div className={styles.analysisSource}>
            <div className={styles.contentContainer}>
              <div
                className={`${styles.leftSide} ${
                  selectedSource ? styles.withSelection : ""
                }`}
              >
                <AnalysisSourceLeftSide
                  bottomTargetPageCount={bottomTargetPageCount}
                  selectedClients={clientSelection.selectedClients.map(
                    (c) => c.id
                  )}
                  sortAnalysisSourceBy={sortAnalysisSourceBy}
                  handleSourceClick={handleSourceClick}
                  selectedPeriods={recAnalysisHeaderState?.selectedPeriods}
                  selectedSource={selectedSource}
                  sources={sources}
                  documentType="RECORDING"
                  windowWidth={windowWidth}
                  tableHeaderData={recAnalysisSourceTableHeaderData}
                  sortingData={{
                    isAsc: analysisSourceAscOrder,
                    columnName: analysisSourceSortColumn,
                  }}
                />
              </div>
              {selectedSource && windowWidth && windowWidth >= 1200 && (
                <AnalysisSourceRightSide
                  selectedSource={selectedSource}
                  topTargetIsOnScreen={topTargetIsOnScreen}
                  selectedPeriods={recAnalysisHeaderState?.selectedPeriods}
                  selectedClients={clientSelection.selectedClients.map(
                    (c) => c.id
                  )}
                  handleBreakdownClose={handleSourceClick}
                  documentType="RECORDING"
                />
              )}
            </div>
          </div>
        )}
        <div
          className={`${
            sources?.data?.sources?.length === sources?.total ? "hidden" : ""
          }`}
          ref={observerBottomTarget}
        ></div>
      </div>
    </Grid>
  );
};

export default RecAnalysisSource;
