import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styles from "./sync.module.scss";
import { SyncLicenseByMediaTypeEnum } from "../../types/enums";
import { SyncLicenseByMediaTypeChartProps } from "../../types/props";
import { ChevronIcon } from "../../atom/Icon";
import Link from "../../atom/Link/Link";
import Block from "./Block";

const prepareChartData = (licensesByMedia = []) => {
  const chartData: SyncLicenseByMediaTypeChartProps[] = [];
  licensesByMedia.forEach((license: any) => {
    switch (license?.mediaType) {
      case SyncLicenseByMediaTypeEnum.Advertising:
        chartData.push({
          name: "Advertising",
          y: license.percentage,
          color: "#F828B1",
          licenseCount: license.licenseCount,
        });
        break;
      case SyncLicenseByMediaTypeEnum.Film:
        chartData.push({
          name: "Film",
          y: license.percentage,
          color: "#F7B06E",
          licenseCount: license.licenseCount,
        });
        break;
      case SyncLicenseByMediaTypeEnum.TV:
        chartData.push({
          name: "TV",
          y: license.percentage,
          color: "#23A4DF",
          licenseCount: license.licenseCount,
        });
        break;
      case SyncLicenseByMediaTypeEnum.VideoGame:
        chartData.push({
          name: "Video Game",
          y: license.percentage,
          color: "#3ACD32",
          licenseCount: license.licenseCount,
        });
        break;
      case SyncLicenseByMediaTypeEnum.ArtistAndRepertoire:
        chartData.push({
          name: "Artist and Repertoire",
          y: license.percentage,
          color: "#D4DCA0",
          licenseCount: license.licenseCount,
        });
        break;
      case SyncLicenseByMediaTypeEnum.Other:
        chartData.push({
          name: "Other",
          y: license.percentage,
          color: "#B2E1E5",
          licenseCount: license.licenseCount,
        });
        break;
      default:
        break;
    }
  });
  return chartData.reverse();
};

const LicenseByMediaTypeBlock = (props: any) => {
  const { licensesByMedia } = props;
  const { t } = useTranslation();
  let chartData: SyncLicenseByMediaTypeChartProps[] = [];

  if (licensesByMedia?.length > 0) {
    chartData = prepareChartData(licensesByMedia);
  }

  const options = {
    accessibility: { enabled: false },
    title: {
      text: "",
    },
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      plotBorderWidth: 0,
      plotShadow: false,
      height: 280,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        borderWidth: 1,
        borderColor: null,
        borderRadius: 0,
        dataLabels: {
          enabled: false,
        },
        size: "100%",
        center: ["50%", "50%"],
      },
      series: {
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        name: "Stats",
        type: "pie",
        innerSize: "79%",
        data: chartData,
      },
    ],
  };

  if (licensesByMedia === null) {
    return <></>;
  }
  return (
    <Block
      title={t("sync.overviewTab.licensesByMediaType.title")}
      subTitle={t(
        "sync.overviewTab.licensesByMediaType.basedOnAllSyncLicenses"
      )}
    >
      <div className={`${styles.licenseByMediaType}`}>
        {licensesByMedia?.length === 0 ? (
          <span className={styles.nodata}>
            {t("sync.overviewTab.licensesByMediaType.noData")}
          </span>
        ) : (
          <div className={styles.stats}>
            <div className={styles.statsChart}>
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <ul className={`${styles.statsBullet}`}>
              {chartData.map((data, i) => {
                let bulletName;
                let bulletClassName;

                switch (data.name) {
                  case "TV":
                    bulletName = t(`sync.overviewTab.bullets.tv`);
                    bulletClassName = "syncMediaTypeTV_GTM";
                    break;
                  case "Film":
                    bulletName = t(`sync.overviewTab.bullets.film`);
                    bulletClassName = "syncMediaTypeFilm_GTM";
                    break;
                  case "Advertising":
                    bulletName = t(`sync.overviewTab.bullets.advertising`);
                    bulletClassName = "syncMediaTypeAdvertising_GTM";
                    break;
                  case "Video Game":
                    bulletName = t(`sync.overviewTab.bullets.videogame`);
                    bulletClassName = "syncMediaTypeVideoGame_GTM";
                    break;
                  case "Other":
                    bulletName = t(`sync.overviewTab.bullets.other`);
                    bulletClassName = "syncMediaTypeOther_GTM";
                    break;
                }

                return (
                  <li className="last:border-none" key={data.name + i}>
                    <div className="flex flex-1 items-center">
                      <span
                        className={`h-3 w-3 rounded-full`}
                        style={{ background: data.color }}
                      ></span>
                      <Link
                        to={`../all`}
                        internalState={{ search: data.name }}
                        text={bulletName}
                        className={`pl-2 hover:no-underline ${bulletClassName}`}
                        iconRight={
                          <ChevronIcon className="h-6 w-6 fill-midnight" />
                        }
                      ></Link>
                    </div>
                    <span className="pr-4 text-3xl">{data.licenseCount}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </Block>
  );
};

export default LicenseByMediaTypeBlock;
