import { lazy } from "react";
import { RequiredAuth } from "./RequiredAuth";
import PubSyncAll from "../publishing/sync/PubSyncAll";
import PubSyncQuoting from "../publishing/sync/PubSyncQuoting";
import PubSyncLicensing from "../publishing/sync/PubSyncLicensing";
import PubSyncReceived from "../publishing/sync/PubSyncReceived";
import PubSyncCompleted from "../publishing/sync/PubSyncCompleted";
import RecSyncOverview from "../recording/sync/RecSyncOverview";
import RecSyncAll from "../recording/sync/RecSyncAll";
import RecSyncQuoting from "../recording/sync/RecSyncQuoting";
import RecSyncLicensing from "../recording/sync/RecSyncLicensing";
import RecSyncReceived from "../recording/sync/RecSyncReceived";
import RecSyncCompleted from "../recording/sync/RecSyncCompleted";
import PubAnalysisSong from "../publishing/analysis/song/PubAnalysisSong";
import PubAnalysisSource from "../publishing/analysis/source/PubAnalysisSource";
import PubAnalysisDigital from "../publishing/analysis/digital/PubAnalysisDigital";
import PubAnalysisType from "../publishing/analysis/type/PubAnalysisType";
import RecAnalysisTrack from "../recording/analysis/track/RecAnalysisTrack";
import RecAnalysisAlbum from "../recording/analysis/album/RecAnalysisAlbum";
import RecAnalysisSource from "../recording/analysis/source/RecAnalysisSource";
import RecAnalysisDigital from "../recording/analysis/digital/RecAnalysisDigital";
import RecAnalysisChannel from "../recording/analysis/channel/RecAnalysisChannel";
import { ROUTES } from "./routes.const";

// Wrappers without lazy loading
import SiteLayout from "../siteLayout/SiteLayout";
import SiteLayoutBasic from "../siteLayoutBasic/siteLayoutBasic";
import Loader from "../../app/atom/Loader/Loader";

const CombinedDashboard = lazy(
  () => import("../combinedDashboard/CombinedDashboard")
);
const Error404 = lazy(() => import("../error/Error404"));
const MecDashboard = lazy(() => import("../mechanical/dashboard"));
const MecStatements = lazy(() => import("../mechanical/statements/Statements"));
const MecContacts = lazy(() => import("../mechanical/contacts/Contacts"));
const MorContacts = lazy(() => import("../more/contacts/Contacts"));
const MoreHelp = lazy(() => import("../more/help/Help"));
const PubDashboard = lazy(() => import("../publishing/dashboard"));
const PubClients = lazy(() => import("../publishing/clients/Clients"));
const PubContacts = lazy(() => import("../publishing/contacts/Contacts"));
const PubAnalysisTerritory = lazy(
  () => import("../publishing/analysis/territory/PubAnalysisTerritory")
);
const PubOverview = lazy(() => import("../publishing/overview/Overview"));
const PubSongRegistration = lazy(
  () => import("../publishing/registration/Registration")
);
const Statements = lazy(
  () => import("../publishing/statements/statements/Statements")
);
const PubTaxDocuments = lazy(
  () => import("../publishing/statements/taxDocuments/TaxDocuments")
);
const PubSyncOverview = lazy(
  () => import("../publishing/sync/PubSyncOverview")
);
const RecAnalysisTerritory = lazy(
  () => import("../recording/analysis/territory/RecAnalysisTerritory")
);

const RecDashboard = lazy(() => import("../recording/dashboard"));
const RecOverview = lazy(() => import("../recording/overview/Overview"));
const DigitalFlash = lazy(
  () => import("../recording/digitalFlash/DigitalFlash")
);
const RecStatements = lazy(
  () => import("../recording/statements/statements/Statements")
);
const RecTaxDocuments = lazy(
  () => import("../recording/statements/taxDocuments/TaxDocuments")
);
const RecContacts = lazy(() => import("../recording/contacts/Contacts"));
const Settings = lazy(() => import("../more/settings/Settings"));
const WelcomeTour = lazy(() => import("../welcomeTour/WelcomeTour"));

export const userRoutes = [
  {
    path: ROUTES.mybmg,
    element: <RequiredAuth />,
    children: [
      {
        path: ROUTES.user,
        element: <RequiredAuth />,
        children: [
          {
            path: `${ROUTES.help}/*`,
            exact: true,
            element: (
              <SiteLayout>
                <MoreHelp />
              </SiteLayout>
            ),
          },
          {
            path: ROUTES.combdashboard,
            element: (
              <SiteLayout
                permissionGroup="threeSixty"
                requiredPermission={["DASHBOARD_LINK", "DASHBOARD_TITLE"]}
              >
                <CombinedDashboard />
              </SiteLayout>
            ),
          },
          {
            path: ROUTES.dashboard,
            element: (
              <SiteLayout
                permissionGroup="publishing"
                requiredPermission={["DASHBOARD_LINK", "DASHBOARD_TITLE"]}
              >
                <PubDashboard />
              </SiteLayout>
            ),
          },
          {
            path: ROUTES.analysis,
            children: [
              {
                path: ROUTES.territories,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="ANALYSIS_LINK"
                  >
                    <PubAnalysisTerritory />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.songs,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="ANALYSIS_LINK"
                  >
                    <PubAnalysisSong />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.sources,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="ANALYSIS_LINK"
                  >
                    <PubAnalysisSource />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.digital,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="ANALYSIS_LINK"
                  >
                    <PubAnalysisDigital />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.type,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="ANALYSIS_LINK"
                  >
                    <PubAnalysisType />
                  </SiteLayout>
                ),
              },
            ],
          },
          {
            path: ROUTES.sync,
            children: [
              {
                path: ROUTES.overview,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="SYNC_LICENSING_LINK"
                  >
                    <PubSyncOverview />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.all,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="SYNC_LICENSING_LINK"
                  >
                    <PubSyncAll />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.quoting,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="SYNC_LICENSING_LINK"
                  >
                    <PubSyncQuoting />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.licensing,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="SYNC_LICENSING_LINK"
                  >
                    <PubSyncLicensing />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.received,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="SYNC_LICENSING_LINK"
                  >
                    <PubSyncReceived />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.completed,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="SYNC_LICENSING_LINK"
                  >
                    <PubSyncCompleted />
                  </SiteLayout>
                ),
              },
            ],
          },
          {
            path: ROUTES.overview,
            element: (
              <SiteLayout
                permissionGroup="publishing"
                requiredPermission="OVERVIEW_LINK"
              >
                <PubOverview />
              </SiteLayout>
            ),
          },
          {
            path: ROUTES.clients,
            element: (
              <SiteLayout
                permissionGroup="publishing"
                requiredPermission="CLIENTS_LINK"
              >
                <PubClients />
              </SiteLayout>
            ),
          },
          {
            path: ROUTES.contacts,
            element: (
              <SiteLayout
                permissionGroup="publishing"
                requiredPermission="CONTACT_ITEM"
              >
                <PubContacts />
              </SiteLayout>
            ),
          },
          {
            path: ROUTES.songregistration,
            element: (
              <SiteLayout
                permissionGroup="publishing"
                requiredPermission="SONG_REGISTRATION_LINK"
              >
                <PubSongRegistration />
              </SiteLayout>
            ),
          },
          {
            path: ROUTES.statements,
            children: [
              {
                path: ROUTES.statements,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    requiredPermission="STATEMENTS_LINK"
                  >
                    <Statements />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.taxdocuments,
                element: (
                  <SiteLayout
                    permissionGroup="publishing"
                    // requiredPermission="STATEMENTS_LINK"
                  >
                    <PubTaxDocuments />
                  </SiteLayout>
                ),
              },
            ],
          },
          {
            path: ROUTES.welcometour,
            element: (
              <SiteLayoutBasic>
                <WelcomeTour />
              </SiteLayoutBasic>
            ),
          },
          {
            path: "",
            element: (
              <SiteLayout>
                <Loader />
              </SiteLayout>
            ),
          },
          {
            path: ROUTES.recording,
            children: [
              {
                path: ROUTES.dashboard,
                element: (
                  <SiteLayout
                    permissionGroup="recording"
                    requiredPermission={["DASHBOARD_LINK", "DASHBOARD_TITLE"]}
                  >
                    <RecDashboard />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.analysis,
                children: [
                  {
                    path: ROUTES.territories,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="ANALYSIS_LINK"
                      >
                        <RecAnalysisTerritory />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.tracks,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="ANALYSIS_LINK"
                      >
                        <RecAnalysisTrack />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.albums,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="ANALYSIS_LINK"
                      >
                        <RecAnalysisAlbum />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.sources,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="ANALYSIS_LINK"
                      >
                        <RecAnalysisSource />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.digital,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="ANALYSIS_LINK"
                      >
                        <RecAnalysisDigital />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.distchannels,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="ANALYSIS_LINK"
                      >
                        <RecAnalysisChannel />
                      </SiteLayout>
                    ),
                  },
                ],
              },
              {
                path: ROUTES.sync,
                children: [
                  {
                    path: ROUTES.overview,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="SYNC_LICENSING_LINK"
                      >
                        <RecSyncOverview />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.all,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="SYNC_LICENSING_LINK"
                      >
                        <RecSyncAll />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.quoting,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="SYNC_LICENSING_LINK"
                      >
                        <RecSyncQuoting />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.licensing,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="SYNC_LICENSING_LINK"
                      >
                        <RecSyncLicensing />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.received,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="SYNC_LICENSING_LINK"
                      >
                        <RecSyncReceived />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.completed,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="SYNC_LICENSING_LINK"
                      >
                        <RecSyncCompleted documentType="RECORDING" />
                      </SiteLayout>
                    ),
                  },
                ],
              },
              {
                path: ROUTES.digitalflash,
                element: (
                  <SiteLayout
                    permissionGroup="recording"
                    requiredPermission="DIGITAL_FLASH_LINK"
                  >
                    <DigitalFlash />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.statements,
                children: [
                  {
                    path: ROUTES.statements,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        requiredPermission="STATEMENTS_LINK"
                      >
                        {/* TODO */}
                        <RecStatements />
                      </SiteLayout>
                    ),
                  },
                  {
                    path: ROUTES.taxdocuments,
                    element: (
                      <SiteLayout
                        permissionGroup="recording"
                        // requiredPermission="STATEMENTS_LINK"
                      >
                        <RecTaxDocuments />
                      </SiteLayout>
                    ),
                  },
                ],
              },
              {
                path: ROUTES.overview,
                element: (
                  <SiteLayout
                    permissionGroup="recording"
                    requiredPermission="OVERVIEW_LINK"
                  >
                    <RecOverview />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.contacts,
                element: (
                  <SiteLayout
                    permissionGroup="recording"
                    requiredPermission="CONTACT_ITEM"
                  >
                    <RecContacts />
                  </SiteLayout>
                ),
              },
              {
                path: "",
                element: (
                  <SiteLayout>
                    <Loader />
                  </SiteLayout>
                ),
              },
            ],
          },
          {
            path: ROUTES.mechanical,
            children: [
              {
                path: ROUTES.dashboard,
                element: (
                  <SiteLayout
                    permissionGroup="mechanical"
                    requiredPermission={["DASHBOARD_LINK", "DASHBOARD_TITLE"]}
                  >
                    <MecDashboard />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.statements,
                element: (
                  <SiteLayout
                    permissionGroup="mechanical"
                    requiredPermission="STATEMENTS_LINK"
                  >
                    {/* TODO */}
                    <MecStatements />
                  </SiteLayout>
                ),
              },
              {
                path: ROUTES.contacts,
                element: (
                  <SiteLayout
                    permissionGroup="mechanical"
                    requiredPermission="CONTACT_ITEM"
                  >
                    <MecContacts />
                  </SiteLayout>
                ),
              },
              {
                path: "",
                element: (
                  <SiteLayout>
                    <Loader />
                  </SiteLayout>
                ),
              },
            ],
          },
          // more
          {
            path: ROUTES.more,
            children: [
              {
                path: ROUTES.contacts,
                element: (
                  <SiteLayout
                    permissionGroup="more"
                    requiredPermission="CONTACT_ITEM"
                  >
                    <MorContacts />
                  </SiteLayout>
                ),
              },
              {
                path: "",
                element: (
                  <SiteLayout>
                    <Loader />
                  </SiteLayout>
                ),
              },
            ],
          },
          // end more
          {
            path: ROUTES.settings,
            element: (
              <SiteLayout>
                <Settings />
              </SiteLayout>
            ),
          },
          {
            path: "*",
            element: (
              <SiteLayoutBasic isLogoLight={true}>
                <Error404 />
              </SiteLayoutBasic>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <SiteLayout>
            <Loader />
          </SiteLayout>
        ),
      },
      {
        path: "*",
        element: (
          <SiteLayoutBasic isLogoLight={true}>
            <Error404 />
          </SiteLayoutBasic>
        ),
      },
    ],
  },
];
