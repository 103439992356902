import { createElement } from "react";
import { useWindowSize } from "../../../app/hooks";

export declare type BlockItemProps = {
  icon: ({ className }: any) => JSX.Element;
  title: string;
  isLastRow: boolean;
  contentLeft?: React.ReactNode;
  contentRight?: React.ReactNode;
};

const BlockItem = ({
  icon,
  title,
  contentLeft,
  contentRight,
  isLastRow,
}: BlockItemProps): JSX.Element => {
  const { width } = useWindowSize();

  const classNames = isLastRow
    ? "py-2 sm:py-3"
    : "border-b border-gray-100 py-2 sm:py-3";

  return width ? (
    <li className={classNames}>
      <div className="flex items-center">
        {icon &&
          createElement(icon, {
            className: "w-5 h-5 stroke-gray-800 mr-2",
          })}
        <div className="flex-1">
          <div
            className="overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium sm:text-base"
            style={width < 728 ? { width: `${width - 100}px` } : {}}
          >
            {title}
          </div>
          <div className="flex justify-between">
            {contentLeft && <div>{contentLeft}</div>}
            {contentRight && <div className="text-end">{contentRight}</div>}
          </div>
        </div>
      </div>
    </li>
  ) : (
    <></>
  );
};

export default BlockItem;
