import { useNavigate } from "react-router-dom";
import Grid from "../../../app/atom/Grid";
import { HelpIcon } from "../../../app/atom/Icon";
import Image from "../../../app/atom/Image";
import { LogoHD } from "../../../app/img";
import Message from "../message/Message";
import * as Paths from "../../routes/paths.const";
import styles from "./header.module.scss";
import { useAppSelector } from "../../../app/redux/hooks";
import { meSelector } from "../../appMain/appMainSlice";

const Header = ({
  hamburgerHandler,
  hamburgerOpenState,
}: {
  hamburgerHandler: (a: boolean) => void;
  hamburgerOpenState: boolean;
}) => {
  const navigate = useNavigate();
  const user = useAppSelector(meSelector);

  const onHelpClick = () => {
    navigate(Paths.PATH_HELP);
  };

  return (
    <header className="relative z-10 order-1">
      <Grid>
        <div className="relative col-span-full">
          <div className="absolute h-14 w-full overflow-hidden bg-midnight"></div>
        </div>
        <div className="relative col-span-2 flex sm:col-span-6">
          <div
            className={`${styles.hamburger} w-14 cursor-pointer duration-500 sm:w-0 sm:duration-75`}
            onClick={() => hamburgerHandler(!hamburgerOpenState)}
          ></div>
          <Image src={LogoHD} alt="logo" className="z-10 h-14"></Image>
        </div>
        <div className="z-10 col-span-2 flex justify-end sm:col-span-6">
          <div className="flex h-full hover:cursor-pointer">
            <div
              className="group flex items-center px-3 hover:bg-blue-800 sm:px-5"
              onClick={onHelpClick}
            >
              <span>
                <HelpIcon className="fill-current h-7 w-7 text-white group-hover:text-limelight" />
              </span>
            </div>
            {!user.isGuided && <Message />}
          </div>
        </div>
      </Grid>
    </header>
  );
};

export default Header;
