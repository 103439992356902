import { useTranslation } from "react-i18next";
import styles from "../analysis.module.scss";
import AnalysisSourceLeftSideItem from "./AnalysisSourceLeftSideItem";
import AnalysisTableHeader from "../analysisTableHeader/AnalysisTableHeader";
import { AnalysisTableHeaderProps } from "../../../types/props";

interface LeftSideProps {
  bottomTargetPageCount: number;
  selectedClients: any[];
  sortAnalysisSourceBy: (columnName: string) => void;
  handleSourceClick: (clickedSource: any) => void;
  selectedPeriods: number[];
  selectedSource: any;
  sources: any;
  documentType: "PUBLISHING" | "RECORDING";
  windowWidth: number | undefined;
  tableHeaderData: Array<AnalysisTableHeaderProps>;
  sortingData: { isAsc: boolean; columnName: string };
}

const AnalysisSourceLeftSide = (props: LeftSideProps) => {
  const { t } = useTranslation();
  const {
    bottomTargetPageCount,
    selectedClients,
    sortAnalysisSourceBy,
    handleSourceClick,
    selectedPeriods,
    selectedSource,
    sources,
    documentType,
    windowWidth,
    tableHeaderData,
    sortingData,
  } = props;

  return (
    <div className={styles.tabTable}>
      <AnalysisTableHeader
        columnsData={tableHeaderData.map((columnData) => {
          return {
            ...columnData,
            clickHandler: sortAnalysisSourceBy,
            sortingData: sortingData,
          };
        })}
      />
      <div>
        <table className={`w-full`}>
          <tbody>
            {sources?.data?.sources?.map((source: any) => {
              if (documentType === "PUBLISHING")
                return (
                  <AnalysisSourceLeftSideItem
                    source={source}
                    sourceId={source.sourceCode}
                    isSelected={
                      selectedSource?.sourceCode === source.sourceCode
                    }
                    sourceCode={selectedSource?.sourceCode}
                    sourceName={selectedSource?.sourceName}
                    handleSourceClick={handleSourceClick}
                    royaltyCurrency={source.formattedRoyalty?.currencySign}
                    royaltyValue={source.formattedRoyalty?.moneyValue}
                    documentType={documentType}
                    selectedPeriods={selectedPeriods}
                    selectedClients={selectedClients}
                    windowWidth={windowWidth}
                  />
                );
              else
                return (
                  <AnalysisSourceLeftSideItem
                    source={source}
                    sourceId={source.sourceName}
                    isSelected={
                      selectedSource?.sourceName === source.sourceName
                    }
                    sourceName={selectedSource?.sourceName}
                    handleSourceClick={handleSourceClick}
                    royaltyCurrency={source.royalty.currency}
                    royaltyValue={source.royalty.formattedLong}
                    documentType={documentType}
                    selectedPeriods={selectedPeriods}
                    selectedClients={selectedClients}
                    windowWidth={windowWidth}
                  />
                );
            })}
          </tbody>
        </table>
        {sources?.data?.sources?.length < sources?.total && (
          <h5 className="p-3">{t("app.loading")}</h5>
        )}
        {bottomTargetPageCount > 0 &&
          sources?.total !== 0 &&
          sources?.data?.sources?.length === sources?.total && (
            <p className="py-8 text-center">
              <b>{t("analysis.seenAll")}</b>
            </p>
          )}
      </div>
    </div>
  );
};

export default AnalysisSourceLeftSide;
