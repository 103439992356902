import React, { FC } from "react";
import {
  AdminIcon,
  ClientsIcon,
  ContactBigIcon,
  FinancialIcon,
  LogoutIcon,
  MechDashIcon,
  OverviewIcon,
  RecJournalIcon,
  SettingIcon,
  StatementsIcon,
  SyncIcon,
  NewsIcon,
  RegistrationIcon,
  CalendarIcon,
  MailIcon,
  PhoneIcon,
  CheckCompleteIcon,
} from "../../app/atom/Icon";

export const DashboardSidebarIcon: FC<{ className: string }> = ({
  className,
}) => (
  <MechDashIcon className={`fill-limelight stroke-midnight ${className}`} />
);

export const MailSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <MailIcon className={`stroke-gray-400 ${className}`} />
);

export const CalendarSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <CalendarIcon className={`stroke-gray-400 ${className}`} />;

export const PhoneSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <PhoneIcon className={`stroke-gray-400 ${className}`} />
);

export const FinancialSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <FinancialIcon className={`stroke-gray-400 ${className}`} />;

export const SettingSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <SettingIcon className={`stroke-gray-400 ${className}`} />;

export const StatementsSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <StatementsIcon className={`stroke-gray-400 ${className}`} />;

export const SyncSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <SyncIcon className={`stroke-gray-400 ${className}`} />
);

export const NewsSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <NewsIcon className={`stroke-gray-400 stroke-1 ${className}`} />
);

export const ContactBigSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <ContactBigIcon className={`stroke-gray-400 ${className}`} />;

export const RegistrationSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <RegistrationIcon className={`fill-gray-400 ${className}`} />;

export const AddClientSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <CheckCompleteIcon className={`${className}`} />;

export const RecJournalSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <RecJournalIcon className={`stroke-gray-400 ${className}`} />;

export const OverviewSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <OverviewIcon className={`stroke-gray-400 ${className}`} />;

export const ClientsSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <ClientsIcon className={`stroke-gray-400 ${className}`} />;

export const AdminSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <AdminIcon className={`stroke-gray-400 ${className}`} />
);

export const LogoutSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <LogoutIcon className={`stroke-gray-400 ${className}`} />
);
