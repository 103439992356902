import { lazy, useMemo, Suspense } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import { CALLBACK } from "../../app/config/constant";
import { OKTA_CONFIG } from "../../app/config/okta";
import { RequiredAuth } from "./RequiredAuth";
import Loader from "../../app/atom/Loader";
import AppMain from "../appMain/AppMain";
import Login from "../siteBasic/login/Login";
import { ROUTES } from "./routes.const";
import { adminRoutes } from "./adminRoutes";
import { userRoutes } from "./userRoutes";

// Wrappers without lazy loading
import SiteBasicWrapper from "../../app/molecules/sideBasicWrapper";
import SiteLayoutBasic from "../siteLayoutBasic/siteLayoutBasic";

// Wrappers without lazy loading
import SiteLayout from "../siteLayout/SiteLayout";

// Route components with lazy loading
const ContactUs = lazy(
  () => import("../siteBasic/contactUsGuest/ContactUsGuest")
);
const ForgotUsername = lazy(() => import("../siteBasic/forgotUsername"));
const ForgotPassword = lazy(() => import("../siteBasic/forgotPassword"));
const ResetSetPassword = lazy(() => import("../siteBasic/resetPassword"));
const Registration = lazy(() => import("../siteBasic/register/Register"));

const PrivacyPolicy = lazy(() => import("../staticPages/PrivacyPolicy"));
const Disclaimer = lazy(() => import("../staticPages/Disclaimer"));
const TermsOfUse = lazy(() => import("../staticPages/TermsOfUse"));
const StyleGuide = lazy(() => import("../styleGuide/StyleGuide"));

const Error403 = lazy(() => import("../error/Error403"));
const Error404 = lazy(() => import("../error/Error404"));
const Error500 = lazy(() => import("../error/Error500"));

function AppRoutes() {
  const navigate = useNavigate();
  const oktaAuth = useMemo(() => new OktaAuth(OKTA_CONFIG), []);

  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const routes = [
    ...adminRoutes,
    ...userRoutes,
    {
      path: ROUTES.mybmg,
      children: [
        {
          path: ROUTES.login,
          exact: true,
          element: (
            <SiteBasicWrapper>
              <Login />
            </SiteBasicWrapper>
          ),
        },
        // contact us (not logged in). ONLY used in help pages. Expect /user/contact
        {
          path: ROUTES.contact,
          element: (
            <SiteBasicWrapper>
              <ContactUs />
            </SiteBasicWrapper>
          ),
        },
        {
          path: `${ROUTES.request}/${ROUTES.account}`,
          exact: true,
          element: (
            <SiteBasicWrapper>
              <Registration />
            </SiteBasicWrapper>
          ),
        },
        {
          path: `${ROUTES.forgot}/${ROUTES.username}`,
          exact: true,
          element: (
            <SiteBasicWrapper>
              <ForgotUsername />
            </SiteBasicWrapper>
          ),
        },
        {
          path: `${ROUTES.forgot}/${ROUTES.password}`,
          exact: true,
          element: (
            <SiteBasicWrapper>
              <ForgotPassword />
            </SiteBasicWrapper>
          ),
        },
        {
          path: `${ROUTES.resetpassword}`,
          exact: true,
          element: (
            <SiteBasicWrapper>
              <ResetSetPassword variant="reset" />
            </SiteBasicWrapper>
          ),
        },
        {
          path: `${ROUTES.setpassword}`,
          exact: true,
          element: (
            <SiteBasicWrapper>
              <ResetSetPassword variant="set" />
            </SiteBasicWrapper>
          ),
        },
        // contact us (not logged in)
        {
          path: ROUTES.contactUs,
          exact: true,
          element: (
            <SiteBasicWrapper>
              <ContactUs />
            </SiteBasicWrapper>
          ),
        },
        {
          path: CALLBACK,
          element: <LoginCallback loadingElement={<Loader />} />,
        },
        {
          path: ROUTES.styleGuide,
          element: (
            <SiteLayout>
              <StyleGuide />
            </SiteLayout>
          ),
        },
        {
          path: ROUTES.privacypolicy,
          element: (
            <SiteLayoutBasic>
              <PrivacyPolicy />
            </SiteLayoutBasic>
          ),
        },
        {
          path: ROUTES.disclaimer,
          element: (
            <SiteLayoutBasic>
              <Disclaimer />
            </SiteLayoutBasic>
          ),
        },
        {
          path: ROUTES.termsofuse,
          element: (
            <SiteLayoutBasic>
              <TermsOfUse />
            </SiteLayoutBasic>
          ),
        },
        // otherwise
        {
          path: "",
          element: (
            <SiteLayout>
              <Loader />
            </SiteLayout>
          ),
        },
        {
          path: "*",
          element: (
            <SiteLayoutBasic isLogoLight={true}>
              <Error404 />
            </SiteLayoutBasic>
          ),
        },
      ],
    },
    {
      path: "",
      element: <RequiredAuth />,
      children: [
        {
          path: "",
          element: (
            <SiteLayout>
              <Loader />
            </SiteLayout>
          ),
        },
      ],
    },
    {
      path: ROUTES.internalError,
      exact: true,
      element: (
        <SiteLayoutBasic isLogoLight={true}>
          <Error500 />
        </SiteLayoutBasic>
      ),
    },
    {
      path: ROUTES.forbidden,
      exact: true,
      element: (
        <SiteLayoutBasic isLogoLight={true}>
          <Error403 />
        </SiteLayoutBasic>
      ),
    },
    {
      path: "*",
      element: (
        <SiteLayoutBasic isLogoLight={true}>
          <Error404 />
        </SiteLayoutBasic>
      ),
    },
  ];

  const routeElements = useRoutes(routes);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Suspense fallback={<Loader />}>
        <AppMain>{routeElements}</AppMain>
      </Suspense>
    </Security>
  );
}

export default AppRoutes;
