import { useTranslation } from "react-i18next";
import Button from "../../../app/atom/Button/Button";
import { ButtonVariantEnum } from "../../../app/types/enums";
import { IAuthentication } from "../../../app/types/interfaces";
import Image from "../../../app/atom/Image/Image";
import { CheckCircleIcon } from "../../../app/atom/Icon";
import AuthErrorMessage from "../../../app/molecules/authErrorMessage/AuthErrorMessage";

const LoginOktaVerify = ({
  authenticationHooks,
}: {
  authenticationHooks: IAuthentication;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {/* HEAD Section Start*/}
      <div className="pb-5 text-lg tracking-7xl text-limelight">
        {authenticationHooks.qrCode &&
          !authenticationHooks.oktaEnrolConcent && (
            <>{t("loginMFA.twoFactorAuthentication")}</>
          )}
        {authenticationHooks.qrCode &&
          !authenticationHooks.loginSucessMsg &&
          authenticationHooks.oktaEnrolConcent && (
            <>
              {t("loginMFA.startEnrolment")}
              {t("loginMFA.oktaVerify")}
            </>
          )}
        {!authenticationHooks.qrCode && (
          <>{t("loginMFA.pushNotificationSent")}</>
        )}
      </div>
      {/* HEAD Section End*/}

      {/* Error Section Start */}
      {authenticationHooks.authApiError && (
        <AuthErrorMessage
          error={authenticationHooks.authApiError}
          id="loginMFAFeedback"
        />
      )}
      {/* Error Section End */}

      {/* Body Section Start*/}
      <>
        {authenticationHooks.introForMFA === "Okta" &&
          !authenticationHooks.oktaEnrolConcent && (
            <div className="text-sm text-white">
              {t("loginMFA.introForOktaDetails")}
            </div>
          )}
        {authenticationHooks.qrCode &&
          !authenticationHooks.loginSucessMsg &&
          authenticationHooks.oktaEnrolConcent && (
            <>
              <div className="mb-8 text-sm text-white">
                {t("loginMFA.oktaVerifDetails")}
              </div>
              <div>
                <Image
                  src={authenticationHooks.qrCode?.href ?? ""}
                  alt="qrCode"
                  className="m-auto h-40 w-40"
                />
                <div className="mb-12"></div>
              </div>
            </>
          )}
        {!authenticationHooks.qrCode && (
          <div className="tracking-7xl text-white">
            {t("loginMFA.verifyCredentialsApprovingPN")}
          </div>
        )}
      </>
      {/* Body Section End*/}

      {/* Below Action Items Start*/}
      <>
        {authenticationHooks.introForMFA === "Okta" &&
          !authenticationHooks.oktaEnrolConcent && (
            <>
              <div className="pt-8">
                <Button
                  className="w-full"
                  onClick={() => authenticationHooks.enrolToMFAHandler("Okta")}
                >
                  {t("loginMFA.enrol")}
                </Button>
              </div>
            </>
          )}
        {!authenticationHooks.qrCode && (
          <div className="flex items-center pt-6 text-white">
            <span>{t("loginMFA.noPNReceived")}</span>{" "}
            <Button
              variant={ButtonVariantEnum.textLink}
              className="-mt-0.5 flex-1 whitespace-pre-wrap p-0 text-gray-200 hover:text-white"
              onClick={authenticationHooks.currentAuthenticatorResend}
            >
              {t("loginMFA.sendAgain")}
            </Button>
          </div>
        )}
        {!!authenticationHooks.qrCode &&
          !!authenticationHooks.loginSucessMsg && (
            <div className="flex flex-col items-center justify-center text-gray-100">
              <span className="text-2xl">Success!</span>
              <div className="my-5">
                <CheckCircleIcon className="h-20 w-20" />
              </div>
              <span className="mb-8 ml-1 text-center">
                {t("loginMFA.oktaVerifSuccessfullyAdded")}
              </span>
              <Button
                variant={ButtonVariantEnum.primaryCta}
                className="w-full"
                onClick={authenticationHooks.successContinue}
              >
                {t("loginMFA.continue")}
              </Button>
            </div>
          )}
      </>
      {/* Below Action Items End*/}
    </div>
  );
};
export default LoginOktaVerify;
