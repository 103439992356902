export enum ButtonVariantEnum {
  primaryCta = "primaryCta",
  primaryNarrowCta = "primaryNarrowCta",
  secondaryCta = "secondaryCta",
  secondaryNarrowCta = "secondaryNarrowCta",
  cleanCta = "cleanCta",
  cleanLightCta = "cleanLightCta",
  outlineCta = "outlineCta",
  errorCta = "errorCta",
  textLink = "textLink",
  dimmedCta = "dimmedCta",
}

export enum TitleVariantEnum {
  primaryTitle = "primaryTitle",
  secondaryTitle = "secondaryTitle",
}

export enum TabsVariantEnum {
  basicTabs = "basicTabs",
  boxedTabs = "boxedTabs",
}

export enum LinkVariantEnum {
  link = "linkNormal",
  linkSmall = "linkSmall",
}

export enum FooterVariantEnum {
  noFooter = "noFooter",
  onlyCopyright = "onlyCopyright",
  linksAndCopyright = "linksAndCopyright",
}

export enum PubSyncTabsEnum {
  overview = 0,
  all = 1,
  quoting = 2,
  licensing = 3,
  received = 4,
  completed = 5,
}

export enum PubSyncTabStatusEnum {
  all = "ALL",
  quoting = "QUOTING",
  licensing = "LICENSING",
  received = "RECEIVED",
  completed = "COMPLETED",
}

export enum RecSyncTabStatusEnum {
  all = "ALL",
  quoting = "QUOTING",
  licensing = "LICENSING",
  received = "RECEIVED",
  completed = "COMPLETED",
}

export enum RecSyncTabsEnum {
  overview = 0,
  all = 1,
  quoting = 2,
  licensing = 3,
  received = 4,
  completed = 5,
}

export enum SyncLicenseByMediaTypeEnum {
  Advertising = "Advertising",
  Film = "Film",
  TV = "TV",
  VideoGame = "Video Game",
  ArtistAndRepertoire = "Artist and Repertoire",
  Other = "Other",
}

export enum StatusEnum {
  Idle = "idle",
  Loading = "loading",
  Fulfilled = "fulfilled",
  Failed = "failed",
}

export enum SortDirectionEnum {
  Ascending = "ASC",
  Descending = "DESC",
}

export enum UserAccountTypeEnum {
  publishing = "PUBLISHING",
  recording = "RECORDING",
  mechanical = "MECHANICAL",
  threesixty = "THREESIXTY",
  admin = "ADMIN",
  unknown = "UNKNOWN",
  all = "ALL",
}

export enum PendingRequestTypeEnum {
  chooseOne = "CHOOSE ONE",
  publishing = "PUBLISHING",
  recording = "RECORDING",
  mechanical = "MECHANICAL",
  threesixty = "THREESIXTY",
  admin = "ADMIN",
  unknown = "UNKNOWN",
}

export enum ClientCommunicationTypeEnum {
  choosetype = "CHOOSE TYPE",
  all = "ALL",
  publishing = "PUBLISHING",
  recording = "RECORDING",
  mechanical = "MECHANICAL",
}

export enum PendingRequestSearchEnum {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  username = "username",
  originatingSystem = "originatingSystem",
}

export enum UserAccountStatusEnum {
  ignored = "IGNORED",
  granted = "GRANTED",
  denied = "DENIED",
}

export enum GroupType {
  Publishing = "PUBLISHING",
  Admin = "ADMIN",
  Recording = "RECORDING",
  Mechanical = "MECHANICAL",
  All = "ALL",
}

export enum EnableSettingsEnum {
  Enabled = "ENABLE",
  Disabled = "DISABLE",
  Group = "GROUP",
}

export enum Publishing_RecordingRole {
  COMPOSER_ARTIST = "COMPOSER_ARTIST",
  MANAGER = "MANAGER",
  ACCOUNTANT = "ACCOUNTANT",
  PUBLISHER = "PUBLISHER",
  LAWYER = "LAWYER",
  AUDITOR = "AUDITOR",
  ARTIST = "ARTIST",
  PRODUCER = "PRODUCER",
  ENGINEER = "ENGINEER",
  STAFF = "STAFF",
  OTHER = "OTHER",
  NONE = "",
}

export enum DashboardListEnum {
  Creative = "CREATIVE",
  Publishing = "PUBLISHING",
  Mechanical = "MECHANICAL",
  Recording = "RECORDING",
}

export enum MechanicalGroupOptionsEnum {
  MechanicalGroup = "Mechanical Group",
}

export enum PublishingRoleEnum {
  Composer = "COMPOSER_ARTIST",
  Manager = "MANAGER",
  Accountant = "ACCOUNTANT",
  Heir = "HEIR",
  Publisher = "PUBLISHER",
  Staff = "STAFF",
  Lawyer = "LAWYER",
  Auditor = "AUDITOR",
}

export enum RecordingRoleEnum {
  Artist = "ARTIST",
  Producer = "PRODUCER",
  Mixer = "MIXER",
  Engineer = "ENGINEER",
  Manager = "MANAGER",
  Accountant = "ACCOUNTANT",
  Lawyer = "LAWYER",
  Auditor = "AUDITOR",
  Staff = "STAFF",
  Other = "OTHER",
}

export enum LanguagesValuesEnum {
  English = "en",
  English_UK = "en_UK",
  English_US = "en_US",
  German = "de",
  French = "fr",
  Swedish = "sv",
  Spanish = "es_ES",
  Italian = "it_IT",
  Portuguese = "pt_BR",
}

export enum PermissionsEnum {
  // Parents
  CLIENTS_LINK_ALL_CLIENTS = "CLIENTS_LINK_ALL_CLIENTS",
  CLIENTS_SHOW_PUBLISHING_PARENTS = "CLIENTS_SHOW_PUBLISHING_PARENTS",

  // royalties
  ROYALTIES_SHOW_PUBLISHING_PIPELINE_INCOME = "ROYALTIES_SHOW_PUBLISHING_PIPELINE_INCOME",
  ROYALTIES_SHOW_PUBLISHING_CURRENT_BALANCE = "ROYALTIES_SHOW_PUBLISHING_CURRENT_BALANCE",
  ROYALTIES_STATEMENT_ONLY = "ROYALTIES_STATEMENT_ONLY",

  // copyright
  COPYRIGHT_SHOW_CONTRIBUTION_PERCENTAGE = "COPYRIGHT_SHOW_CONTRIBUTION_PERCENTAGE",
  COPYRIGHT_SHOW_COPYRIGHT_TAB = "COPYRIGHT_SHOW_COPYRIGHT_TAB",
  COPYRIGHT_SHOW_ISWC = "COPYRIGHT_SHOW_ISWC",
  COPYRIGHT_SHOW_SONG_CODE = "COPYRIGHT_SHOW_SONG_CODE",
  SONG_REGISTRATION = "SONG_REGISTRATION",

  // sync
  SYNC_DISPLAY_SYNC_PAGES = "SYNC_DISPLAY_SYNC_PAGES",
  SYNC_DISPLAY_2017_ON_DATA = "SYNC_DISPLAY_2017_ON_DATA",

  // advance request
  ROYALTIES_SHOW_PUBLISHING_ADVANCE_REQUEST = "ROYALTIES_SHOW_PUBLISHING_ADVANCE_REQUEST",

  // song registration
  UPDATE_BANK_DETAILS = "UPDATE_BANK_DETAILS",

  // recording, parents

  // parents_recording

  CLIENTS_LINK_ALL_RECORDING_CLIENTS = "CLIENTS_LINK_ALL_RECORDING_CLIENTS",
  CLIENTS_SHOW_RECORDING_PARENTS = "CLIENTS_SHOW_RECORDING_PARENTS",

  // royalties
  ROYALTIES_DISPLAY_UNITS = "ROYALTIES_DISPLAY_UNITS",
  ROYALTIES_SHOW_RECORDING_PIPELINE_INCOME = "ROYALTIES_SHOW_RECORDING_PIPELINE_INCOME",
  ROYALTIES_SHOW_RECORDING_CURRENT_BALANCE = "ROYALTIES_SHOW_RECORDING_CURRENT_BALANCE",
  ANALYSIS_DISPLAY_REC_SOURCES_PAGES = "ANALYSIS_DISPLAY_REC_SOURCES_PAGES",

  // journal
  REC_JOURNAL_CREATOR = "REC_JOURNAL_CREATOR",
  REC_JOURNAL_REVIEWER = "REC_JOURNAL_REVIEWER",

  // advance request
  ROYALTIES_SHOW_RECORDING_ADVANCE_REQUEST = "ROYALTIES_SHOW_RECORDING_ADVANCE_REQUEST",

  // digital flash
  DIGITAL_FLASH = "DIGITAL_FLASH",
  SHOW_RECORDING_UPDATE_BANK_DETAILS = "SHOW_RECORDING_UPDATE_BANK_DETAILS",
}

export enum FormElementsEnum {
  Input = "Input",
  Dropdown = "Dropdown",
}

export enum AdminTypesEnum {
  SuperAdmin = "BMG Super-Administrators",
  Admin = "BMG Administrators",
  ReadOnlyAdmin = "BMG Read-Only Administrators",
  AffiliateAdmin = "BMG Affiliate Administrators",
}

export enum TableVariantEnum {
  publishing = "PUBLISHING",
  recording = "RECORDING",
  mechanical = "MECHANICAL",
}

export enum SelectSettingsVariantEnum {
  group = "GROUP",
  enable = "ENABLE",
  disable = "DISABLE",
}

export enum RegistrationStatusesEnum {
  Registered = "Registered",
  RegistrationSent = "Registration Sent",
  InProgress = "In Progress",
  Pending = "Pending",
}

export enum RegistrationSearchTagCategoryTypeEnum {
  Status = "STATUS",
  Song = "SONG",
  Territory = "TERRITORY",
  Society = "SOCIETY",
}
