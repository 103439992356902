import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RequiredAuth } from "./RequiredAuth";
import { ROUTES } from "./routes.const";

// Route components without lazy loading
//import Home from "../home/Home";

// Wrappers without lazy loading
import SiteLayoutAdmin from "../siteLayoutAdmin/SiteLayoutAdmin";

// Wrapper only needed for 404 page (load with lazy loading)
const SiteLayoutBasic = lazy(
  () => import("../siteLayoutBasic/siteLayoutBasic")
);

// Redirecting to home
const UserAccountsAddPage = lazy(() => import("../home/Home"));

const ActivityLog = lazy(
  () => import("../admin/pages/ActivityLog/ActivityLog")
);
const AdminAccounts = lazy(
  () => import("../admin/pages/AdminAccounts/AdminAccounts")
);
const AddNewAdminAccountsPage = lazy(
  () => import("../admin/pages/AddEditAdminAccounts/AddEditAdminAccounts")
);
const AdminGroups = lazy(() => import("../admin/pages/AdminGroups"));
const AddNewAdminGroupPage = lazy(
  () => import("../admin/pages/AddEditAdminGroup/AddEditAdminGroup")
);
const AddNewsPage = lazy(
  () => import("../admin/pages/AddEditNews/AddEditNews")
);
const AdvanceRequestConfig = lazy(
  () => import("../admin/pages/AdvanceRequestСonfig/AdvanceRequestConfig")
);
const ClientsPage = lazy(
  () => import("../admin/pages/ClientsPage/ClientsPage")
);
const ClientCommunication = lazy(
  () => import("../admin/pages/ClientCommunication/ClientCommunication")
);
const ClosingDateDetails = lazy(
  () => import("../admin/pages/ClosingDateDetails/ClosingDateDetails")
);
const EditPendingRequest = lazy(
  () => import("../admin/pages/EditPendingRequest/EditPendingRequestPage")
);
const EmailFooters = lazy(
  () => import("../admin/pages/EmailFooters/EmailFooters")
);
const Error404 = lazy(() => import("../error/Error404"));
const Elasticsearch = lazy(
  () => import("../admin/pages/Elasticsearch/Elasticsearch")
);
const Groups = lazy(() => import("../admin/pages/Groups"));
const Misc = lazy(() => import("../admin/pages/Misc/Misc"));
const News = lazy(() => import("../admin/pages/News"));
const PendingRequest = lazy(
  () => import("../admin/pages/PendingRequest/PendingRequest")
);
const RequestArchive = lazy(
  () => import("../admin/pages/RequestArchive/RequestArchive")
);
const RequestArchiveEditPage = lazy(
  () => import("../admin/pages/RequestArchiveEditPage/RequestArchiveEditPage")
);
const SystemClosingDates = lazy(
  () => import("../admin/pages/SystemClosingDates/SystemClosingDates")
);
const Users = lazy(() => import("../admin/pages/Users/Users"));

const ParentsPage = lazy(() => import("../admin/pages/Parents/ParentsPage"));

const AddParentsPage = lazy(
  () => import("../admin/pages/Parents/Add/AddParentsPage")
);

const EditParentsPage = lazy(
  () => import("../admin/pages/Parents/Edit/EditParentsPage")
);

const BulkImportPage = lazy(
  () => import("../admin/pages/Parents/BulkImport/BulkImportPage")
);

const AddEditGroup = lazy(
  () => import("../admin/pages/AddEditGroup/AddEditGroup")
);

const AddEditUsers = lazy(() => import("../admin/pages/AddEditUsers"));

const BulkImportUserAccounts = lazy(
  () => import("../admin/pages/BulkImportUserAccounts")
);

export const adminRoutes = [
  {
    path: ROUTES.mybmg,
    children: [
      {
        path: ROUTES.admin,
        element: <RequiredAuth />,
        children: [
          {
            path: ROUTES.pending,
            element: (
              <SiteLayoutAdmin>
                <PendingRequest />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.pending}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <EditPendingRequest />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.archive,
            element: (
              <SiteLayoutAdmin>
                <RequestArchive />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.archive}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <RequestArchiveEditPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.users,
            element: (
              <SiteLayoutAdmin>
                <Users />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.users}/${ROUTES.add}`,
            element: (
              <SiteLayoutAdmin>
                <AddEditUsers />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.users}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <AddEditUsers />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.users}/${ROUTES.bulkImport}`,
            element: (
              <SiteLayoutAdmin>
                <BulkImportUserAccounts />
              </SiteLayoutAdmin>
            ),
          },
          {
            // TODO add new component for Add new user from request archive page
            path: `${ROUTES.users}/${ROUTES.addById}`,
            element: (
              <SiteLayoutAdmin>
                <UserAccountsAddPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.clients,
            element: (
              <SiteLayoutAdmin>
                <ClientsPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.parents,
            element: (
              <SiteLayoutAdmin>
                <ParentsPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.parents}/${ROUTES.bulkImport}`,
            element: (
              <SiteLayoutAdmin>
                <BulkImportPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.parents}/${ROUTES.add}`,
            element: (
              <SiteLayoutAdmin>
                <AddParentsPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.parents}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <EditParentsPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.usergroups,
            element: (
              <SiteLayoutAdmin>
                <Groups />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.usergroups}/${ROUTES.publishing}/${ROUTES.add}`,
            element: (
              <SiteLayoutAdmin>
                <AddEditGroup />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.usergroups}/${ROUTES.publishing}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <AddEditGroup />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.usergroups}/${ROUTES.recording}/${ROUTES.add}`,
            element: (
              <SiteLayoutAdmin>
                <AddEditGroup />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.usergroups}/${ROUTES.recording}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <AddEditGroup />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.admins,
            element: (
              <SiteLayoutAdmin>
                <AdminAccounts />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.admins}/${ROUTES.add}`,
            element: (
              <SiteLayoutAdmin>
                <AddNewAdminAccountsPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.admins}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <AddNewAdminAccountsPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.admingroups,
            element: (
              <SiteLayoutAdmin>
                <AdminGroups />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.admingroups}/${ROUTES.add}`,
            element: (
              <SiteLayoutAdmin>
                <AddNewAdminGroupPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.admingroups}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <AddNewAdminGroupPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.news,
            element: (
              <SiteLayoutAdmin>
                <News />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.news}/${ROUTES.add}`,
            element: (
              <SiteLayoutAdmin>
                <AddNewsPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.news}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <AddNewsPage />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.activitylog,
            element: (
              <SiteLayoutAdmin>
                <ActivityLog />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.duedates,
            element: (
              <SiteLayoutAdmin>
                <SystemClosingDates />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.duedates}/${ROUTES.add}`,
            element: (
              <SiteLayoutAdmin>
                <ClosingDateDetails />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: `${ROUTES.duedates}/${ROUTES.edit}`,
            element: (
              <SiteLayoutAdmin>
                <ClosingDateDetails />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.communication,
            element: (
              <SiteLayoutAdmin>
                <ClientCommunication />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.footers,
            element: (
              <SiteLayoutAdmin>
                <EmailFooters />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.advancerequest,
            element: (
              <SiteLayoutAdmin>
                <AdvanceRequestConfig />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.elasticsearch,
            element: (
              <SiteLayoutAdmin>
                <Elasticsearch />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: ROUTES.misc,
            element: (
              <SiteLayoutAdmin>
                <Misc />
              </SiteLayoutAdmin>
            ),
          },
          {
            path: "",
            element: (
              <Navigate
                to={`/${ROUTES.mybmg}/${ROUTES.admin}/${ROUTES.users}`}
              />
            ),
          },
          {
            path: "*",
            element: (
              <SiteLayoutBasic isLogoLight={true}>
                <Error404 />
              </SiteLayoutBasic>
            ),
          },
        ],
      },
    ],
  },
];
