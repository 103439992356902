import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import { fetchAnalysisSource, downloadAnalysisData } from "../pubAnalysisAPI";

export const PubAnalysisSourcePageInitialState = {
  data: {
    periodIds: [],
    clientIds: [],
    clientLists: [],
  },
  start: 0,
  count: 200,
  sortColumn: "royalties",
  isAsc: true,
  filterText: "",
};

export interface PubAnalysisSourceState {
  analysisSourcePageState: {
    data: {
      periodIds: Array<number>;
      clientIds: any;
      clientLists: any;
    };
    start: number;
    count: number;
    sortColumn: string;
    isAsc: boolean;
    filterText: string;
  };
  source: {
    data: {
      sources: Array<any>;
      totalRoyalty: Record<string, any>;
    };
    total: number;
  };
  downloadStatus: "loading" | "idle" | "failed";
  analysisSourceStatus: "init" | "idle" | "loading" | "failed";
  noDataFlag?: "" | "noData" | "noPeriodData" | "noSearchData";
}

const initialState: PubAnalysisSourceState = {
  analysisSourcePageState: PubAnalysisSourcePageInitialState,
  source: {
    data: {
      sources: [],
      totalRoyalty: {},
    },
    total: 0,
  },
  downloadStatus: "idle",
  analysisSourceStatus: "init",
  noDataFlag: undefined,
};

export const fetchAnalysisSourceThunk = createAsyncThunk(
  "publishing/analysisSource",
  async (payload: any, thunkAPI: any) => {
    const response: any = await fetchAnalysisSource(payload, thunkAPI);
    return response;
  }
);

export const fetchMoreAnalysisSourceThunk = createAsyncThunk(
  "publishing/moreAnalysisSource",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisSource(payload, thunkAPI);
    return response;
  }
);

export const pubAnalysisSourceDownload = createAsyncThunk(
  "pubAnalysis/downloadSource",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const pubAnalysisSourceSlice = createSlice({
  name: "pubAnalysisSource",
  initialState,
  reducers: {
    resetAnalysisSource: (state, action: any) => {
      state.source = initialState.source;
    },
    updateAnalysisSourcePageState: (state: any, action: any) => {
      state.analysisSourcePageState.data.periodIds =
        action.payload.data.periodIds;
      state.analysisSourcePageState.data.clientIds =
        action.payload.data.clientIds;
      state.analysisSourcePageState.data.clientLists =
        action.payload.data.clientLists;
      state.analysisSourcePageState.start = action.payload.start;
      state.analysisSourcePageState.count = action.payload.count;
      state.analysisSourcePageState.sortColumn = action.payload.sortColumn;
      state.analysisSourcePageState.filterText = action.payload.filterText;
      state.analysisSourcePageState.isAsc = action.payload.isAsc;
    },
  },
  extraReducers: (builder) => {
    builder
      // download
      .addCase(pubAnalysisSourceDownload.fulfilled, (state) => {
        state.downloadStatus = "idle";
      })
      .addCase(pubAnalysisSourceDownload.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(pubAnalysisSourceDownload.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addCase(
        fetchAnalysisSourceThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisSourceStatus = "idle";
          state.source.data = action.payload.data;
          state.source.total = action.payload.total;
          state.noDataFlag =
            action.payload?.total === 0
              ? state?.analysisSourcePageState?.filterText?.length > 0
                ? "noSearchData"
                : state?.analysisSourcePageState?.data?.periodIds?.length === 1
                ? "noPeriodData"
                : "noData"
              : "";
        }
      )
      .addCase(fetchMoreAnalysisSourceThunk.fulfilled, (state, action) => {
        state.analysisSourceStatus = "idle";
        state.source.data.sources = [
          ...state.source.data.sources,
          ...action.payload.data.sources,
        ];
        state.source.data.totalRoyalty = action.payload.data.totalRoyalty;
        state.source.total = action.payload.total;
      })
      .addMatcher(
        isAnyOf(
          fetchMoreAnalysisSourceThunk.pending,
          fetchAnalysisSourceThunk.pending
        ),
        (state) => {
          state.analysisSourceStatus = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchMoreAnalysisSourceThunk.rejected,
          fetchAnalysisSourceThunk.rejected
        ),
        (state) => {
          state.analysisSourceStatus = "failed";
        }
      );
  },
});

export const pubAnalysisSourcePageStateSelector = (state: RootState) =>
  state.pubAnalysisSource.analysisSourcePageState;
export const pubAnalysisSourceSelector = (state: RootState) =>
  state.pubAnalysisSource.source;
export const pubAnalysisSourceStatusSelector = (state: RootState) =>
  state.pubAnalysisSource.analysisSourceStatus;
export const pubAnalysisSourceDownloadStatusSelector = (state: RootState) =>
  state.pubAnalysisSource.downloadStatus;
export const pubAnalysisNoDataFlagSelector = (state: RootState) =>
  state.pubAnalysisSource.noDataFlag;

export const {
  resetAnalysisSource: resetAnalysisSourceAction,
  updateAnalysisSourcePageState: updateAnalysisSourcePageStateAction,
} = pubAnalysisSourceSlice.actions;

export default pubAnalysisSourceSlice.reducer;
