import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { downloadAnalysisData, fetchAnalysisAlbums } from "../recAnalysisAPI";
import { RootState } from "../../../../app/redux/store";

export const RecAnalysisAlbumPageInitialState = {
  data: {
    clientIds: [],
    clientLists: [],
    periodIds: [],
  },
  start: 0,
  count: 200,
  sortColumn: "royalties",
  sortAscending: true,
  filterText: "",
  filterColumn: [],
};

export interface RecAnalysisAlbumProps {
  data: {
    albums: Array<any>;
    totalRoyalty: Record<string, any>;
  };
  total: number;
}

export interface RecAnalysisAlbumState {
  analysisAlbumPageState: {
    data: {
      clientIds: Array<number>;
      clientLists: Array<any>;
      periodIds: Array<number>;
    };
    start: number;
    count: number;
    sortColumn: string;
    sortAscending: boolean;
    filterText: string;
    filterColumn: Array<string>;
  };
  albums: RecAnalysisAlbumProps;
  analysisAlbumStatus: "init" | "idle" | "loading" | "failed";
  downloadStatus: "loading" | "idle" | "failed";
  noDataFlag?: "" | "noData" | "noPeriodData" | "noSearchData";
}

const initialState: RecAnalysisAlbumState = {
  analysisAlbumPageState: RecAnalysisAlbumPageInitialState,
  albums: {
    data: {
      albums: [],
      totalRoyalty: {},
    },
    total: 0,
  },
  analysisAlbumStatus: "init",
  downloadStatus: "idle",
  noDataFlag: undefined,
};

export const fetchAnalysisAlbumsThunk = createAsyncThunk(
  "recording/analysisAlbums",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisAlbums(payload, thunkAPI);
    return response;
  }
);

export const fetchMoreAnalysisAlbumsThunk = createAsyncThunk(
  "recording/moreAnalysisAlbums",
  async (payload: any, thunkAPI: any) => {
    const response = await fetchAnalysisAlbums(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisAlbumsDownload = createAsyncThunk(
  "recAnalysis/downloadAlbums",
  async (payload: any, thunkAPI) => {
    const response = await downloadAnalysisData(payload, thunkAPI);
    return response;
  }
);

export const recAnalysisAlbumSlice = createSlice({
  name: "recAnalysisAlbum",
  initialState,
  reducers: {
    resetAnalysisAlbums: (state, action: any) => {
      state.albums = initialState.albums;
    },
    updateAnalysisAlbumPageState: (state: any, action: any) => {
      state.analysisAlbumPageState.data.clientIds =
        action.payload.data.clientIds;
      state.analysisAlbumPageState.data.clientLists =
        action.payload.data.clientLists;
      state.analysisAlbumPageState.data.periodIds =
        action.payload.data.periodIds;
      state.analysisAlbumPageState.start = action.payload.start;
      state.analysisAlbumPageState.count = action.payload.count;
      state.analysisAlbumPageState.sortColumn = action.payload.sortColumn;
      state.analysisAlbumPageState.sortAscending = action.payload.sortAscending;
      state.analysisAlbumPageState.filterColumn = action.payload.filterColumn;
      state.analysisAlbumPageState.filterText = action.payload.filterText;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAnalysisAlbumsThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisAlbumStatus = "idle";
          state.albums.data = action.payload.data;
          state.albums.total = action.payload.total;
          state.noDataFlag =
            action.payload?.total === 0
              ? state.analysisAlbumPageState.filterText.length > 0
                ? "noSearchData"
                : state.analysisAlbumPageState.data.periodIds.length === 1
                ? "noPeriodData"
                : "noData"
              : "";
        }
      )
      .addCase(
        fetchMoreAnalysisAlbumsThunk.fulfilled,
        (state: any, action: any) => {
          state.analysisAlbumStatus = "idle";
          state.albums.data.albums = [
            ...state.albums.data.albums,
            ...action.payload.data.albums,
          ];
          state.albums.data.totalRoyalty = action.payload.data.totalRoyalty;
          state.albums.total = action.payload.total;
        }
      )
      .addCase(recAnalysisAlbumsDownload.fulfilled, (state) => {
        state.downloadStatus = "idle";
      })
      .addCase(recAnalysisAlbumsDownload.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(recAnalysisAlbumsDownload.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addMatcher(
        isAnyOf(
          fetchAnalysisAlbumsThunk.pending,
          fetchMoreAnalysisAlbumsThunk.pending
        ),
        (state) => {
          state.analysisAlbumStatus = "loading";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchAnalysisAlbumsThunk.rejected,
          fetchMoreAnalysisAlbumsThunk.rejected
        ),
        (state) => {
          state.analysisAlbumStatus = "failed";
        }
      );
  },
});

export const recAnalysisAlbumPageStateSelector = (state: RootState) =>
  state.recAnalysisAlbum.analysisAlbumPageState;
export const recAnalysisAlbumsSelector = (state: RootState) =>
  state.recAnalysisAlbum.albums;
export const recAnalysisAlbumPeriodIdsSelector = (state: RootState) =>
  state.recAnalysisAlbum.analysisAlbumPageState.data.periodIds;
export const recAnalysisDownloadStatusSelector = (state: RootState) =>
  state.recAnalysisAlbum.downloadStatus;
export const recAnalysisAlbumStatusSelector = (state: RootState) =>
  state.recAnalysisAlbum.analysisAlbumStatus;
export const recAnalysisNoDataFlagSelector = (state: RootState) =>
  state.recAnalysisAlbum.noDataFlag;

export const {
  resetAnalysisAlbums: resetAnalysisAlbumsAction,
  updateAnalysisAlbumPageState: updateAnalysisAlbumPageStateAction,
} = recAnalysisAlbumSlice.actions;

export default recAnalysisAlbumSlice.reducer;
