import { AnalysisTableHeaderProps } from "../../../types/props";
import styles from "../analysis.module.scss";

export const pubAnalysisDigitalTableHeaderData: Array<AnalysisTableHeaderProps> =
  [
    {
      className: `${styles.digitalCol}`,
      title: "analysis.digital.digitalSource",
      columnName: "sourceNameAndCode",
    },
    {
      className: `sortByRoyalties_GTM ${styles.royaltiesCol}`,
      title: "analysis.digital.royalties",
      columnName: "royalties",
      isRightAligned: true,
    },
  ];

export const recAnalysisDigitalTableHeaderData: Array<AnalysisTableHeaderProps> =
  [
    {
      className: `${styles.sourceCol}`,
      title: "analysis.digital.source",
      columnName: "originalSource",
    },
    {
      className: `sortByRoyalties_GTM ${styles.royaltiesCol}`,
      title: "analysis.digital.royalties",
      columnName: "royalties",
      isRightAligned: true,
    },
  ];
