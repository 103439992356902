import React from "react";
import { MessageItemProps } from "../../../app/types/props";
import styles from "./message.module.scss";
import { CloseIcon } from "../../../app/atom/Icon";
import Button from "../../../app/atom/Button/Button";
import { ButtonVariantEnum } from "../../../app/types/enums";

const MessageItem = (props: MessageItemProps): JSX.Element => {
  const { onSoftDeleteMessage, onMarkAsReadMessage, ...rest } = props;
  const { id, title, text, timeElapsed, read } = rest.message;

  const handleDeleteMessage = () => {
    onSoftDeleteMessage(id);
  };

  const handleMarkAsRead = () => {
    onMarkAsReadMessage(id);
  };

  return (
    <li className="group relative cursor-default py-2 pl-7 hover:bg-limelight hover:text-midnight">
      <div onClick={handleMarkAsRead} className="mr-8">
        <div
          className={`${styles.messageItemTime} ${
            read && styles.messageItemTimeRead
          }`}
        >
          {timeElapsed}
        </div>
        <div className="mb-1 text-xs font-medium">{title}</div>
        <div
          className={styles.messageBody}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>

      <div className="absolute right-0 top-0">
        <Button
          className="relative p-0 text-base tracking-3xl text-midnight no-underline hover:underline"
          variant={ButtonVariantEnum.textLink}
          icon={<CloseIcon className="hidden h-6 w-6 group-hover:block" />}
          onClick={handleDeleteMessage}
        />
      </div>
    </li>
  );
};

export default MessageItem;
