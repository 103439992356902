import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";
import {
  FetchAnalysisSongBreakdown_Territory,
  FetchAnalysisSongBreakdown_Type,
  FetchAnalysisSongBreakdown_Source,
  FetchAnalysisSongBreakdown_Details,
  downloadSongBreakdownData,
} from "./pubAnalysisSongBreakdownAPI";
import { getPubIncomeTypeDetails } from "../../../../app/utils/incomeType";

export declare type PubAnalysisSongBreakdownProps =
  | {
      songId: number;
      periodIds: Array<number>;
      data: any;
    }
  | null
  | undefined;

export interface pubAnalysisBreakdownState {
  songBreakdownType: {
    dataArray: Array<PubAnalysisSongBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  songBreakdownTerritory: {
    dataArray: Array<PubAnalysisSongBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  songBreakdownSource: {
    dataArray: Array<PubAnalysisSongBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  songBreakdownDetail: {
    dataArray: Array<PubAnalysisSongBreakdownProps>;
    status: "noData" | "idle" | "loading" | "failed";
  };
  songBreakdownDownloadStatus: "idle" | "loading" | "failed";
}

const initialState: pubAnalysisBreakdownState = {
  songBreakdownType: { dataArray: [], status: "noData" },
  songBreakdownTerritory: { dataArray: [], status: "noData" },
  songBreakdownSource: { dataArray: [], status: "noData" },
  songBreakdownDetail: { dataArray: [], status: "noData" },
  songBreakdownDownloadStatus: "idle",
};

export const FetchPubAnalysisSongBreakdown_Type_Thunk = createAsyncThunk(
  "publishing/FetchPubAnalysisSongBreakdown_Type",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSongBreakdown_Type(params, thunkAPI);
  }
);

export const FetchPubAnalysisSongBreakdown_Territory_Thunk = createAsyncThunk(
  "publishing/FetchPubAnalysisSongBreakdown_Territory",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSongBreakdown_Territory(params, thunkAPI);
  }
);

export const FetchPubAnalysisSongBreakdown_Source_Thunk = createAsyncThunk(
  "publishing/FetchPubAnalysisSongBreakdown_Source",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSongBreakdown_Source(params, thunkAPI);
  }
);

export const FetchPubAnalysisSongBreakdown_Details_Thunk = createAsyncThunk(
  "publishing/FetchPubAnalysisSongBreakdown_Details",
  async (params: any, thunkAPI) => {
    return await FetchAnalysisSongBreakdown_Details(params, thunkAPI);
  }
);

export const pubAnalysisSongBreakdown_Download_Thunk = createAsyncThunk(
  "pubAnalysis/breakdown/downloadSong",
  async (payload: any, thunkAPI) => {
    const response = await downloadSongBreakdownData(payload, thunkAPI);
    return response;
  }
);

export const FindPubAnalysisSongBreakdown = (
  songs: Array<PubAnalysisSongBreakdownProps>,
  songId: number
): PubAnalysisSongBreakdownProps => {
  const breakdown = songs.find((song) => {
    return song?.songId === songId;
  });
  return breakdown ? breakdown : null;
};

export const pubAnalysisBreakdownSlice = createSlice({
  name: "pubAnalysisSongBreakdown",
  initialState,
  reducers: {
    clearTypeBreakdownData: (state, action: any) => {
      state.songBreakdownType.dataArray = [];
      state.songBreakdownType.status = "noData";
    },
    clearTerritoryBreakdownData: (state, action: any) => {
      state.songBreakdownTerritory.dataArray = [];
      state.songBreakdownTerritory.status = "noData";
    },
    clearSourceBreakdownData: (state, action: any) => {
      state.songBreakdownSource.dataArray = [];
      state.songBreakdownSource.status = "noData";
    },
    clearDetailsBreakdownData: (state, action: any) => {
      state.songBreakdownDetail.dataArray = [];
      state.songBreakdownDetail.status = "noData";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        FetchPubAnalysisSongBreakdown_Type_Thunk.fulfilled,
        (state, action) => {
          const typeData = action.payload;
          typeData.songId = action.meta.arg.songId;
          typeData?.incomeTypes?.forEach((incomeType: any) => {
            const color = getPubIncomeTypeDetails(
              incomeType?.mainIncType
            ).color;
            incomeType.color = color;
          });
          state.songBreakdownType.dataArray.push(typeData);
          state.songBreakdownType.status = "idle";
        }
      )
      .addCase(FetchPubAnalysisSongBreakdown_Type_Thunk.pending, (state) => {
        state.songBreakdownType.status = "loading";
      })
      .addCase(FetchPubAnalysisSongBreakdown_Type_Thunk.rejected, (state) => {
        state.songBreakdownType.status = "failed";
      })
      .addCase(
        FetchPubAnalysisSongBreakdown_Territory_Thunk.fulfilled,
        (state, action) => {
          const territoryData = action.payload;
          territoryData.songId = action.meta.arg.songId;
          state.songBreakdownTerritory.dataArray.push(territoryData);
          state.songBreakdownTerritory.status = "idle";
        }
      )
      .addCase(
        FetchPubAnalysisSongBreakdown_Territory_Thunk.pending,
        (state) => {
          state.songBreakdownTerritory.status = "loading";
        }
      )
      .addCase(
        FetchPubAnalysisSongBreakdown_Territory_Thunk.rejected,
        (state) => {
          state.songBreakdownTerritory.status = "failed";
        }
      )
      .addCase(
        FetchPubAnalysisSongBreakdown_Source_Thunk.fulfilled,
        (state, action) => {
          const sourceData = action.payload;
          sourceData.songId = action.meta.arg.songId;
          state.songBreakdownSource.dataArray.push(sourceData);
          state.songBreakdownSource.status = "idle";
        }
      )
      .addCase(FetchPubAnalysisSongBreakdown_Source_Thunk.pending, (state) => {
        state.songBreakdownSource.status = "loading";
      })
      .addCase(FetchPubAnalysisSongBreakdown_Source_Thunk.rejected, (state) => {
        state.songBreakdownSource.status = "failed";
      })
      .addCase(
        FetchPubAnalysisSongBreakdown_Details_Thunk.fulfilled,
        (state, action) => {
          const detailsData = action.payload;
          detailsData.songId = action.meta.arg.songId;
          state.songBreakdownDetail.dataArray.push(detailsData);
          state.songBreakdownDetail.status = "idle";
        }
      )
      .addCase(FetchPubAnalysisSongBreakdown_Details_Thunk.pending, (state) => {
        state.songBreakdownDetail.status = "loading";
      })
      .addCase(
        FetchPubAnalysisSongBreakdown_Details_Thunk.rejected,
        (state) => {
          state.songBreakdownDetail.status = "failed";
        }
      )
      // download
      .addCase(pubAnalysisSongBreakdown_Download_Thunk.fulfilled, (state) => {
        state.songBreakdownDownloadStatus = "idle";
      })
      .addCase(pubAnalysisSongBreakdown_Download_Thunk.pending, (state) => {
        state.songBreakdownDownloadStatus = "loading";
      })
      .addCase(pubAnalysisSongBreakdown_Download_Thunk.rejected, (state) => {
        state.songBreakdownDownloadStatus = "failed";
      });
  },
});

export const pubAnalysisSongBreakdownTypeSelector = (state: RootState) =>
  state.pubAnalysisSongBreakdown.songBreakdownType;
export const pubAnalysisSongBreakdownTerritorySelector = (state: RootState) =>
  state.pubAnalysisSongBreakdown.songBreakdownTerritory;
export const pubAnalysisSongBreakdownSourceSelector = (state: RootState) =>
  state.pubAnalysisSongBreakdown.songBreakdownSource;
export const pubAnalysisSongBreakdownDetailSelector = (state: RootState) =>
  state.pubAnalysisSongBreakdown.songBreakdownDetail;
export const pubAnalysisSongBreakdownDownloadStatusSelector = (
  state: RootState
) => state.pubAnalysisSongBreakdown.songBreakdownDownloadStatus;

export const {
  clearTypeBreakdownData: clearTypeBreakdownDataAction,
  clearTerritoryBreakdownData: clearTerritoryBreakdownDataAction,
  clearSourceBreakdownData: clearSourceBreakdownDataAction,
  clearDetailsBreakdownData: clearDetailsBreakdownDataAction,
} = pubAnalysisBreakdownSlice.actions;

export default pubAnalysisBreakdownSlice.reducer;
