import React from "react";
function CheckCompleteIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 27 27"
      fill="none"
      strokeWidth={0}
      name="CheckCompleteIcon"
      {...props}
    >
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" strokeWidth="1.2" />
        <path
          stroke="currentColor"
          strokeWidth="1.4"
          d="m8.353 13.047 3.079 3.025 6.3-5.894"
        />
      </g>
    </svg>
  );
}

export default CheckCompleteIcon;
