import React from "react";
function PubDashIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      stroke="#fff"
      fill="none"
      strokeWidth={0}
      name="PubDashIcon"
      {...props}
    >
      <path d="M12.558 25.215c6.9 0 12.494-5.593 12.494-12.493S19.458.228 12.558.228C5.658.228.065 5.822.065 12.722c0 6.9 5.593 12.493 12.493 12.493Z" />
      <path
        strokeWidth=".6"
        d="M10.771 18.585c-1.298 0-2.354-1.044-2.354-2.327s1.056-2.327 2.354-2.327c1.299 0 2.355 1.044 2.355 2.327s-1.056 2.327-2.355 2.327ZM13.126 5v9.82a2.776 2.776 0 0 0-2.355-1.3c-1.528 0-2.77 1.228-2.77 2.738s1.242 2.738 2.77 2.738 2.772-1.228 2.772-2.738V5.994l4.137 4.09.295-.292L13.126 5Z"
      />
    </svg>
  );
}

export default PubDashIcon;
