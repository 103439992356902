import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import RecAnalysisHeader from "../RecAnalysisHeader";
import Search from "../../../../app/molecules/search/Search";
import debounce from "../../../../app/utils/debounce";
import {
  recAnalysisDigitalPageStateSelector,
  fetchAnalysisDigitalThunk,
  fetchMoreAnalysisDigitalThunk,
  updateAnalysisDigitalPageStateAction,
  resetAnalysisDigitalAction,
  recAnalysisDigitalSelector,
  recAnalysisDigitalDownload,
  recAnalysisDigitalStatusSelector,
  recAnalysisDownloadStatusSelector,
  recAnalysisNoDataFlagSelector,
  RecAnalysisDigitalPageInitialState,
} from "./recAnalysisDigitalSlice";
import {
  clearProductsBreakdownDataAction,
  clearTerritoryBreakdownDataAction,
  clearTrendBreakdownDataAction,
} from "./recAnalysisDigitalBreakdownSlice";
import deepCompare from "../../../../app/utils/deepCompare";
import { windowScrollToTop } from "../../../../app/utils/scrolling";
import { recClientSelectionSelector } from "../../recroot/recrootSlice";
import useInfiniteScroll from "../../../../app/hooks/useInfiniteScroll";
import Grid from "../../../../app/atom/Grid/Grid";
import Loader from "../../../../app/atom/Loader/Loader";
import { recAnalysisHeaderSelector } from "../recAnalysisHeaderSlice";
import styles from "../../../../app/molecules/analysis/analysis.module.scss";
import { getNow, getToday } from "../../../../app/utils/dateTime";
import {
  recAnalysisDigitalBreakdownProductsSelector,
  recAnalysisDigitalBreakdownTerritorySelector,
  recAnalysisDigitalBreakdownTrendSelector,
} from "./recAnalysisDigitalBreakdownSlice";
import throttle from "../../../../app/utils/throttle";
import AnalysisDigitalRightSide from "../../../../app/molecules/analysis/analysisDigital/AnalysisDigitalRightSide";
import AnalysisDigitalLeftSide from "../../../../app/molecules/analysis/analysisDigital/AnalysisDigitalLeftSide";
import { useWindowSize } from "../../../../app/hooks";
import { recAnalysisDigitalTableHeaderData } from "../../../../app/molecules/analysis/analysisDigital/analysisDigitalTableHeader.const";

const RecAnalysisDigital = (): JSX.Element => {
  const { authState } = useOktaAuth();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { width: windowWidth } = useWindowSize();

  // selectors
  const clientSelection = useAppSelector(recClientSelectionSelector);
  const recAnalysisDigitalPageState = useAppSelector(
    recAnalysisDigitalPageStateSelector
  );
  const digital = useAppSelector(recAnalysisDigitalSelector);
  const recAnalysisDigitalStatus = useAppSelector(
    recAnalysisDigitalStatusSelector
  );
  const digitalBreakdown_Products = useAppSelector(
    recAnalysisDigitalBreakdownProductsSelector
  );
  const digitalBreakdown_Territories = useAppSelector(
    recAnalysisDigitalBreakdownTerritorySelector
  );
  const digitalBreakdown_Trend = useAppSelector(
    recAnalysisDigitalBreakdownTrendSelector
  );
  const recAnalysisDownloadStatus = useAppSelector(
    recAnalysisDownloadStatusSelector
  );
  const recAnalysisNoDataFlag = useAppSelector(recAnalysisNoDataFlagSelector);
  const recAnalysisHeaderState = useAppSelector(recAnalysisHeaderSelector);

  // state
  const [selectedDigital, setSelectedDigital] = useState<any>(undefined);
  const [analysisDigitalStart, setAnalysisDigitalStart] = useState(
    recAnalysisDigitalPageState.start
  );
  const [analysisDigitalSortColumn, setAnalysisDigitalSortColumn] = useState(
    recAnalysisDigitalPageState.sortColumn
  );
  const [analysisDigitalAscOrder, setAnalysisDigitalAscOrder] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState(
    recAnalysisDigitalPageState.filterText
  );
  const [loaderFlag, setLoaderFlag] = useState(true);
  const [totalAmount, setTotalAmount] = useState({
    currency: digital.data?.totalRoyalty?.currency,
    value: digital.data?.totalRoyalty?.formattedLong,
  });
  const [isSortingLoading, setIsSortingLoading] = useState(false);

  // observers
  const observerTopTarget = useRef<HTMLDivElement | null>(null);
  const { isOnScreen: topTargetIsOnScreen } =
    useInfiniteScroll(observerTopTarget);
  const observerBottomTarget = useRef<HTMLDivElement | null>(null);
  const {
    pageCount: bottomTargetPageCount,
    setPageCount: setBottomTargetPageCount,
  } = useInfiniteScroll(observerBottomTarget);

  // dispatch refs
  const dispatchedAnalysisDigital = useRef<any>();
  const dispatchedAnalysisMoreDigital = useRef<any>();
  const dispatchedDownload = useRef<any>();

  // abort functions
  const abortDispatchedDownload = useCallback(() => {
    if (dispatchedDownload.current) dispatchedDownload.current.abort();
  }, []);
  const abortDispatchedAnalysisDigital = useCallback(() => {
    if (dispatchedAnalysisDigital.current)
      dispatchedAnalysisDigital.current.abort();
  }, []);
  const abortDispatchedAnalysisMoreDigital = useCallback(() => {
    if (dispatchedAnalysisMoreDigital.current)
      dispatchedAnalysisMoreDigital.current.abort();
  }, []);

  const resetAnalysisDigital = useCallback(() => {
    dispatch(resetAnalysisDigitalAction({}));
  }, [dispatch]);

  const sortAnalysisDigitalBy = useCallback(
    (columnName: string) => {
      setIsSortingLoading(true);
      resetAnalysisDigital();
      setLoaderFlag(true);
      setAnalysisDigitalSortColumn(columnName);
      if (recAnalysisDigitalPageState.sortColumn !== columnName) {
        setAnalysisDigitalAscOrder(true);
      } else {
        setAnalysisDigitalAscOrder(!analysisDigitalAscOrder);
      }
    },
    [
      analysisDigitalAscOrder,
      recAnalysisDigitalPageState.sortColumn,
      resetAnalysisDigital,
    ]
  );

  const handleAnalysisDigitalFetch = useMemo(
    () =>
      debounce((pageState: any) => {
        if (pageState.start === 0) {
          abortDispatchedAnalysisDigital();
          dispatchedAnalysisDigital.current = dispatch(
            fetchAnalysisDigitalThunk(pageState)
          );
        } else {
          abortDispatchedAnalysisMoreDigital();
          dispatchedAnalysisMoreDigital.current = dispatch(
            fetchMoreAnalysisDigitalThunk(pageState)
          );
        }
      }, 500),
    [
      abortDispatchedAnalysisDigital,
      abortDispatchedAnalysisMoreDigital,
      dispatch,
    ]
  );

  const handleSearch = useCallback(
    (newSearchText: string) => {
      setLoaderFlag(true);
      resetAnalysisDigital();
      setSearchText(newSearchText);
      window?.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    [resetAnalysisDigital]
  );

  const handleDigitalClick = (clickedDigital: any) => {
    !selectedDigital ||
    (selectedDigital &&
      selectedDigital.sourceName !== clickedDigital.sourceName)
      ? setSelectedDigital(clickedDigital)
      : setSelectedDigital(undefined);
  };

  const handlePeriodSelection = () => setSelectedDigital(undefined);

  const fetchMoreData = useCallback(() => {
    setAnalysisDigitalStart(
      () => (bottomTargetPageCount - 1) * recAnalysisDigitalPageState.count
    );
    setLoaderFlag(false);
  }, [bottomTargetPageCount, recAnalysisDigitalPageState.count]);

  const clearAnalysisDigitalBreakdown = useMemo(
    () =>
      throttle((state: any) => {
        dispatch(clearProductsBreakdownDataAction(state));
        dispatch(clearTrendBreakdownDataAction(state));
        dispatch(clearTerritoryBreakdownDataAction(state));
      }, 500),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      abortDispatchedAnalysisDigital();
      abortDispatchedAnalysisMoreDigital();
      abortDispatchedDownload();
      // clear analysis song page state (keep)
      dispatch(
        updateAnalysisDigitalPageStateAction(RecAnalysisDigitalPageInitialState)
      );
    };
  }, [
    abortDispatchedAnalysisDigital,
    abortDispatchedAnalysisMoreDigital,
    abortDispatchedDownload,
    dispatch,
  ]);

  useEffect(() => {
    const hasMore = digital?.data?.sources?.length < digital?.total;
    if (hasMore && bottomTargetPageCount > 1) {
      fetchMoreData();
    }
  }, [
    fetchMoreData,
    bottomTargetPageCount,
    digital?.data?.sources?.length,
    digital?.total,
  ]);

  useEffect(() => {
    if (
      !authState?.isAuthenticated ||
      clientSelection.selectedClients?.length === 0 ||
      recAnalysisHeaderState.selectedPeriods?.length === 0
    ) {
      return;
    }
    const recAnalysisDigitalState = {
      ...recAnalysisDigitalPageState,
      data: {
        periodIds: recAnalysisHeaderState.selectedPeriods,
        clientIds: clientSelection?.selectedClients?.map((c) => c.id) || [],
        clientLists: [],
      },
      start: analysisDigitalStart,
      sortColumn: analysisDigitalSortColumn,
      filterText: searchText,
      sortAscending: analysisDigitalAscOrder,
    };
    if (!deepCompare(recAnalysisDigitalState, recAnalysisDigitalPageState)) {
      dispatch(updateAnalysisDigitalPageStateAction(recAnalysisDigitalState));
      handleAnalysisDigitalFetch(recAnalysisDigitalState);
      clearAnalysisDigitalBreakdown(recAnalysisDigitalState);
      setSelectedDigital(undefined);
    }
    if (
      !deepCompare(
        //ignore start value in deep compare
        { ...recAnalysisDigitalState, start: null },
        { ...recAnalysisDigitalPageState, start: null }
      )
    ) {
      setAnalysisDigitalStart(0);
      setBottomTargetPageCount(1);
    }
  }, [
    analysisDigitalAscOrder,
    analysisDigitalSortColumn,
    analysisDigitalStart,
    authState?.isAuthenticated,
    clearAnalysisDigitalBreakdown,
    clientSelection.selectedClients,
    dispatch,
    handleAnalysisDigitalFetch,
    recAnalysisDigitalPageState,
    recAnalysisHeaderState.selectedPeriods,
    searchText,
    setBottomTargetPageCount,
  ]);

  useEffect(() => {
    if (digital?.data?.sources?.length) {
      setIsSortingLoading(false);
    }
  }, [digital]);

  useEffect(() => {
    if (!isSortingLoading) {
      setTotalAmount({
        currency: digital?.data?.totalRoyalty?.currency,
        value: digital?.data?.totalRoyalty?.formattedLong,
      });
    }
  }, [digital, isSortingLoading]);

  const handleDownloadBtn = useCallback(() => {
    const params = {
      data: {
        periodIds: recAnalysisHeaderState?.selectedPeriods,
        clientIds: clientSelection.selectedClients.map((client) => client.id),
        analyzeBy: "BY_REC_DIGITAL_SOURCES",
      },
      fileName:
        "Royalties_" +
        t("analysis.digital.byRecDigitalResources") +
        "_" +
        getToday() +
        "-" +
        getNow() +
        ".xlsx",
    };
    abortDispatchedDownload();
    dispatchedDownload.current = dispatch(recAnalysisDigitalDownload(params));
  }, [
    abortDispatchedDownload,
    clientSelection.selectedClients,
    dispatch,
    recAnalysisHeaderState?.selectedPeriods,
    t,
  ]);

  return (
    <Grid>
      {(recAnalysisDigitalStatus === "loading" ||
        digitalBreakdown_Products.status === "loading" ||
        digitalBreakdown_Territories.status === "loading" ||
        digitalBreakdown_Trend.status === "loading" ||
        recAnalysisDownloadStatus === "loading") &&
        loaderFlag && <Loader />}
      <div className={styles.analysis}>
        <RecAnalysisHeader
          documentType="RECORDING"
          tab="digital"
          handlePeriodSelection={handlePeriodSelection}
          onDownloadBtnClick={handleDownloadBtn}
          isDownloadBtnDisabled={digital?.total === 0 ? true : false}
        />
        <div ref={observerTopTarget} id="observerTopTargetId" />
        {recAnalysisNoDataFlag !== undefined && (
          <div
            id="tab-header"
            className={`sticky top-0 z-[5] ${styles.tabHeader} ${
              topTargetIsOnScreen === false && bottomTargetPageCount > 0
                ? "shadow-lg"
                : ""
            }`}
          >
            <div className={styles.title}>
              <p className={styles.period}>
                {
                  recAnalysisHeaderState.tabSelectedPeriodTitle[
                    recAnalysisHeaderState.selectedPeriodText?.titleVariant
                  ]
                }{" "}
                <span>{recAnalysisHeaderState.selectedPeriodText.details}</span>
              </p>
              {(!!digital?.data?.sources?.length || isSortingLoading) && (
                <p className={styles.totalAmount}>
                  {totalAmount.currency}
                  <strong>{` ${totalAmount.value}`}</strong>
                </p>
              )}
            </div>
            <div className={styles.actions}>
              <Search
                placeholderText={t("analysis.digital.searchTextPlaceholder")}
                searchText={recAnalysisDigitalPageState.filterText}
                resultsCount={digital?.total}
                onSearchChange={handleSearch}
                showBackToTop={!topTargetIsOnScreen}
                onBackToTopClick={windowScrollToTop}
                status={recAnalysisDigitalStatus}
                className={styles.search}
              />
            </div>
            {(!!digital?.data?.sources?.length || isSortingLoading) && (
              <p className={styles.info}>
                {t("analysis.digital.viewTheDigitalSourceShort")}
              </p>
            )}
          </div>
        )}
        {recAnalysisNoDataFlag && recAnalysisNoDataFlag?.length && (
          <div className="pl-10 text-xs sm:text-sm">
            {t(`analysis.${recAnalysisNoDataFlag}`)}
          </div>
        )}
        {recAnalysisNoDataFlag === "" && (
          <div className={styles.analysisDigital}>
            <div className={styles.contentContainer}>
              <div
                className={`${styles.leftSide} ${
                  selectedDigital ? styles.withSelection : ""
                }`}
              >
                <AnalysisDigitalLeftSide
                  sortAnalysisDigitalBy={sortAnalysisDigitalBy}
                  digitalTotal={digital?.total}
                  digitalSources={digital?.data?.sources}
                  handleDigitalClick={handleDigitalClick}
                  selectedPeriods={recAnalysisHeaderState?.selectedPeriods}
                  selectedClients={clientSelection.selectedClients.map(
                    (c) => c.id
                  )}
                  selectedDigital={selectedDigital}
                  bottomTargetPageCount={bottomTargetPageCount}
                  documentType="RECORDING"
                  windowWidth={windowWidth}
                  tableHeaderData={recAnalysisDigitalTableHeaderData}
                  sortingData={{
                    isAsc: analysisDigitalAscOrder,
                    columnName: analysisDigitalSortColumn,
                  }}
                />
              </div>
              {selectedDigital && windowWidth && windowWidth >= 1200 && (
                <AnalysisDigitalRightSide
                  selectedDigital={selectedDigital}
                  topTargetIsOnScreen={topTargetIsOnScreen}
                  selectedPeriods={recAnalysisHeaderState?.selectedPeriods}
                  selectedClients={clientSelection.selectedClients.map(
                    (c) => c.id
                  )}
                  handleBreakdownClose={handleDigitalClick}
                  documentType="RECORDING"
                />
              )}
            </div>
          </div>
        )}
        <div
          className={`${
            digital?.data?.sources?.length === digital?.total ? "hidden" : ""
          }`}
          ref={observerBottomTarget}
        ></div>
      </div>
    </Grid>
  );
};

export default RecAnalysisDigital;
