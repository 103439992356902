import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SpinnerIcon } from "../Icon";

const Loader: FC<{ label?: string | undefined }> = ({ label }) => {
  const { t } = useTranslation();
  return (
    <div aria-label="Loading..." role="status" className="sinner-box">
      <div className="flex flex-col items-center justify-center space-y-2 rounded-md bg-midnight px-8 py-4">
        <SpinnerIcon className="h-24 w-24" />
        <span className="tracking-7xl text-white">
          {label !== undefined ? label : t("app.loading")}
        </span>
      </div>
    </div>
  );
};

export default Loader;
