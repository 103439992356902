import React, { FC } from "react";
import {
  AdminIcon,
  ClientsIcon,
  ContactBigIcon,
  DashboardIcon,
  FinancialIcon,
  HelpIcon,
  LogoutIcon,
  MechDashIcon,
  OverviewIcon,
  PubDashIcon,
  RecDashIcon,
  RecJournalIcon,
  SettingIcon,
  StatementsIcon,
  SyncIcon,
  DigitalFlashIcon,
  RegistrationIcon,
} from "../../app/atom/Icon";

export const DashboardSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <DashboardIcon className={`stroke-gray-400 ${className}`} />;

export const PubDashSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <PubDashIcon className={`stroke-midnight ${className}`} />;

export const FinancialSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <FinancialIcon className={`stroke-gray-400 ${className}`} />;

export const SyncSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <SyncIcon className={`stroke-gray-400 ${className}`} />
);

export const DigitalFlashSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <DigitalFlashIcon className={`fill-gray-400 ${className}`} />;

export const StatementsSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <StatementsIcon className={`stroke-gray-400 ${className}`} />;

export const OverviewSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <OverviewIcon className={`stroke-gray-400 ${className}`} />;

export const ClientsSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <ClientsIcon className={`stroke-gray-400 ${className}`} />;

export const ContactBigSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <ContactBigIcon className={`stroke-gray-400 ${className}`} />;

export const RecDashSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <RecDashIcon className={`stroke-midnight text-midnight ${className}`} />;

export const RecJournalSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <RecJournalIcon className={`stroke-gray-400 ${className}`} />;

export const MechDashSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <MechDashIcon className={`stroke-midnight ${className}`} />;

export const SettingSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <SettingIcon className={`stroke-gray-400 ${className}`} />;

export const HelpSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <HelpIcon className={`fill-current text-gray-400 ${className}`} />
);

export const AdminSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <AdminIcon className={`stroke-gray-400 ${className}`} />
);

export const LogoutSidebarIcon: FC<{ className: string }> = ({ className }) => (
  <LogoutIcon className={`stroke-gray-400 ${className}`} />
);

export const RegistrationSidebarIcon: FC<{ className: string }> = ({
  className,
}) => <RegistrationIcon className={`fill-gray-400 ${className}`} />;
